import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import {
  showToast,
  findWarningType,
  formatCurrencyValue,
  isPermissionToAccess,
  errorHandler,
  extractQueryParams,
} from "../helper-methods";
import { getInfluencerBalance } from "../http-calls";
import BankInfoUpdateModal from "../components/modals/BankInfoUpdateModal";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import RequestWithdrawalModal from "../components/modals/RequestWithdrawalModal";
import { install as installResizeObserver } from "resize-observer";
import KYCAlertCard from "../components/KYCAlertCard";
import { APP_NAME } from "../config/index";
import EarningsGraph from "../components/statementTabs/EarningsGraph";
import EarningsTable from "../components/statementTabs/EarningsTable";
import PayoutRequests from "../components/statementTabs/PayoutRequests";
import EarningStatistics from "../components/statementTabs/EarningStatistics";
import { useMemo } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/actions";
import ErrorBoundary from "../components/ErrorBoundary";
import ReKYCCard from "../components/ReKYCCard";
import SvgIcons from "../components/SvgIcons";
import { statementPageTabsConfig } from "../config/helper-config";

const StatementPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [balance, setBalance] = useState({
    availableToWithdraw: 0,
    availableSoon: 0,
    inTransitToBank: 0,
    isAvailableOnlyForInstantWithdrawal: false,
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [requestWithdrawalModal, setRequestWithdrawalModal] = useState({
    isOpen: false,
  });
  const [bankInfoModal, setBankInfoModal] = useState({
    isOpen: false,
  });

  const canMakePayoutRequest = useMemo(
    () => isPermissionToAccess("statement", "canMakePayoutRequest", true),
    []
  );

  const _toggleActiveTab = (tabId = "1") => {
    if (activeTab === tabId) return;

    history.replace(`/statement?tabId=${tabId}`);

    setActiveTab(tabId);
  };

  const _updateUserDetails = async () => {
    try {
      dispatch(showLoader("Updating Profile"));
      await fetchAndModifiedUserDetails()(dispatch);
      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _toggleRequestWithdrawalModal = (isOpen = false) => {
    setRequestWithdrawalModal({
      isOpen,
    });
  };

  const _toggleBankInfoModal = (isOpen = false) => {
    setBankInfoModal({
      isOpen,
    });
  };

  const _getInfluencerBalance = async () => {
    try {
      setLoading(true);

      const res = await getInfluencerBalance();

      setBalance({
        availableToWithdraw: res?.availableToWithdrawal,
        availableSoon: res?.availableSoon,
        inTransitToBank: res?.transitToBank,
        isAvailableOnlyForInstantWithdrawal:
          res?.isAvailableOnlyForInstantWithdrawal,
      });

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("statement", "canViewStatement")) {
      showToast("Unauthorized", "error");
      // Assistant dont have access to view Feed
      history.push("/my-profile");
    }

    // we have to install resize observer manually as it gives error
    // apexchart problem - resize observe used in apexchart
    installResizeObserver();

    dispatch(fetchAndModifiedUserDetails());

    _getInfluencerBalance();

    const { tabId } = extractQueryParams();

    if (+tabId && +tabId > 0 && +tabId < 5) {
      _toggleActiveTab(tabId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin">
          <Col sm={12} className="pgPadding bg-white pb-4">
            {/* stripe kyc alert card */}
            <KYCAlertCard />

            <ReKYCCard />

            <div className="pgTitle">
              <h2>Statement</h2>
            </div>

            {findWarningType(userData?.user)?.bank ? (
              <UncontrolledAlert color="warning">
                <i className="fa fa-bell alertIconWarning" />
                {`${APP_NAME} cannot transfer payments to you currently. Please add a bank account to withdraw your funds.`}{" "}
                <hr style={{ margin: "0.8rem 0" }} />
                <Button
                  className="themeBtn addBtn mt-1"
                  onClick={() => _toggleBankInfoModal(true)}
                >
                  Add Bank Account
                </Button>
              </UncontrolledAlert>
            ) : null}

            {/* creator rank */}
            {userData?.user?.rankText ? (
              <Alert className="creatorRank">
                <h4 className="alert-heading">Well done!</h4>
                <p>
                  You are top {userData.user.rankText}% of all creators
                  globally!
                </p>
              </Alert>
            ) : null}

            <Row className="mb-2 mb-md-4 customMarginStatement">
              <Col xs="6" md="4" className="customPaddingStatement">
                <div className="balanceStatus">
                  <p>Available soon</p>
                  <img
                    src={"/assets/img/dollar.svg"}
                    alt="Dollar"
                    loading="lazy"
                  />
                  <h5>
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.availableSoon || 0)
                    )}
                  </h5>
                </div>
              </Col>
              <Col xs="6" md="4" className="customPaddingStatement">
                <div className="balanceStatus">
                  <p>In transit to bank</p>
                  <img src={"/assets/img/bank.svg"} alt="Bank" loading="lazy" />
                  <h5>
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.inTransitToBank || 0)
                    )}
                  </h5>
                </div>
              </Col>

              <Col xs="12" md="4">
                <div className="balanceStatus">
                  <p>Available to withdraw</p>
                  <h5 className="mt-3 mt-md-4">
                    {loading ? (
                      <Spinner type="grow" />
                    ) : (
                      formatCurrencyValue(balance?.availableToWithdraw || 0)
                    )}
                  </h5>

                  {canMakePayoutRequest && balance?.availableToWithdraw > 0 ? (
                    <Button onClick={() => _toggleRequestWithdrawalModal(true)}>
                      Request Withdrawal
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Nav tabs className="customTabs customTabsStatement">
              {statementPageTabsConfig?.map((each, index) => (
                <NavItem key={each?.value}>
                  <NavLink
                    active={activeTab === `${index + 1}`}
                    onClick={() => {
                      _toggleActiveTab(`${index + 1}`);
                    }}
                  >
                    <span>{each?.label}</span>

                    <SvgIcons type={each?.icon} />
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent
              activeTab={activeTab}
              className="customTabContent"
              style={{ overflow: "unset" }}
            >
              {/* earning summary with graph */}
              <TabPane tabId="1">
                <ErrorBoundary>
                  <EarningsGraph tabId="1" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* earnings tab */}
              <TabPane tabId="2">
                <ErrorBoundary>
                  <EarningsTable tabId="2" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* "Payout Requests" TAB starts  */}
              <TabPane tabId="3">
                <ErrorBoundary>
                  <PayoutRequests tabId="3" activeTab={activeTab} />
                </ErrorBoundary>
              </TabPane>

              {/* "Earning Statistics" TAB starts  */}
              <TabPane tabId="4">
                <ErrorBoundary>
                  <EarningStatistics
                    tabId="4"
                    activeTab={activeTab}
                    toggleActiveTab={_toggleActiveTab}
                  />
                </ErrorBoundary>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>

      <RequestWithdrawalModal
        history={history}
        isOpen={requestWithdrawalModal.isOpen}
        isOnlyInstant={balance?.isAvailableOnlyForInstantWithdrawal}
        availableToWithdraw={balance?.availableToWithdraw}
        canMakePayoutRequest={canMakePayoutRequest}
        toggle={() => _toggleRequestWithdrawalModal()}
        resetTabData={() => _getInfluencerBalance()}
        userData={userData}
      />

      <BankInfoUpdateModal
        isOpen={bankInfoModal.isOpen}
        onDismiss={() => _toggleBankInfoModal()}
        onAccountUpdate={_updateUserDetails}
        accountInfo={userData.accountInfo}
        user={userData?.user}
      />
    </div>
  );
};

export default StatementPage;
