import React, { Component } from "react";
import CreatePost from "../CreatePost";
import { CustomInput } from "reactstrap";
import {
  changeDateFormat,
  convertDate,
  deepClone,
  errorHandler,
  isPermissionToAccess,
  showToast,
  uploadFileOnServer,
  uploadPhotoToCloudinary,
} from "../../helper-methods";
import moment from "moment";
import PostCostModal from "../modals/PostCostModal";
import SoundRecordUploadModal from "../modals/SoundRecordUploadModal";
import { connect } from "react-redux";
import {
  fetchAndModifiedUserDetails,
  hideLoader,
  showLoader,
} from "../../redux/actions";
import { saveContents, updateProfile } from "../../http-calls";
import { UPLOAD_LIMIT, UPLOAD_LIMIT_TEXT } from "../../config";

class SubscriptionWelcomeMessage extends Component {
  state = {
    modals: [false],
  };

  constructor(props) {
    super(props);
    this.createPostRef = React.createRef();
    this.postCostModalRef = React.createRef();
    this.state = {
      user: null,
      isFirstTime: true,
      isImage: {
        welcomeMessage: false,
        plusWelcomeMessage: false,
        premiumWelcomeMessage: false,
      },
      isSound: {
        welcomeMessage: false,
        plusWelcomeMessage: false,
        premiumWelcomeMessage: false,
      },
      isPoll: {
        welcomeMessage: false,
        plusWelcomeMessage: false,
        premiumWelcomeMessage: false,
      },
      activeTab: "1",
      activeIndex: 0,
      modals: [false],

      deletedMedias: {
        welcomeMessage: [],
        plusWelcomeMessage: [],
        premiumWelcomeMessage: [],
      },
      uploadImages: {
        welcomeMessage: [],
        plusWelcomeMessage: [],
        premiumWelcomeMessage: [],
      },
      postedData: {
        welcomeMessage: {
          uploadImages: [],
          recordedAudio: null,
          pollForm: {
            question: "",
            options: [],
            duration: 0,
          },
          startDate: null,
          expirationPeriod: 0,
          postPrice: 0,
          // plusPostPrice: 0,
          // premiumPostPrice: 0,
          postType: "instant",
          endDate: null,
        },
        plusWelcomeMessage: {
          uploadImages: [],
          recordedAudio: null,
          pollForm: {
            question: "",
            options: [],
            duration: 0,
          },
          startDate: null,
          expirationPeriod: 0,
          postPrice: 0,
          // plusPostPrice: 0,
          // premiumPostPrice: 0,
          postType: "instant",
          endDate: null,
        },
        premiumWelcomeMessage: {
          uploadImages: [],
          recordedAudio: null,
          pollForm: {
            question: "",
            options: [],
            duration: 0,
          },
          startDate: null,
          expirationPeriod: 0,
          postPrice: 0,
          // plusPostPrice: 0,
          // premiumPostPrice: 0,
          postType: "instant",
          endDate: null,
        },
      },
      showCostModal: {
        isOpen: false,
        openFor: null,
      },
      showSoundRecordingModal: {
        isOpen: false,
        openFor: null,
      },

      showWelcomeMessage: {
        welcomeMessage: false,
        plusWelcomeMessage: false,
        premiumWelcomeMessage: false,
      },
      welcomeMessage: {
        welcomeMessage: null,
        plusWelcomeMessage: null,
        premiumWelcomeMessage: null,
      },
      _setWelcomeMessge: this._setWelcomeMessge.bind(this),
      userAssistant: null,
      multiTierWelcomeMessageConfig: [
        { label: "Basic", id: 0, tierKeyName: "welcomeMessage" },
        { label: "Plus", id: 1, tierKeyName: "plusWelcomeMessage" },
        { label: "Premium", id: 2, tierKeyName: "premiumWelcomeMessage" },
      ],
    };
  }

  componentDidMount = () => {
    this.props.fetchAndModifiedUserDetails();
    const { userData } = this.props;

    // For Creator Assistant
    if (userData?.userAssistant?.isAssistant) {
      this.setState({ userAssistant: userData.userAssistant });
    }

    if (this.createPostRef && this.createPostRef.current) {
      this.createPostRef.current._initializeIsTyped();
    }

    // this.postCostModalRef.current._initializeForm();
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.isFirstTime ||
      JSON.stringify(props.userData.user) !== JSON.stringify(state.user)
    ) {
      const defaultPostedData = {
        uploadImages: [],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        startDate: null,
        expirationPeriod: 0,
        postPrice: 0,
        // plusPostPrice: 0,
        // premiumPostPrice: 0,
        postType: "instant",
        endDate: null,
      };

      return {
        user: props.userData.user,
        showWelcomeMessage: {
          welcomeMessage:
            props.userData.user &&
            props.userData.user.welcomeMessage &&
            props.userData.user.welcomeMessage.isActive
              ? props.userData.user.welcomeMessage.isActive
              : false,
          plusWelcomeMessage:
            props.userData.user &&
            props.userData.user.plusWelcomeMessage &&
            props.userData.user.plusWelcomeMessage.isActive
              ? props.userData.user.plusWelcomeMessage.isActive
              : false,
          premiumWelcomeMessage:
            props.userData.user &&
            props.userData.user.premiumWelcomeMessage &&
            props.userData.user.premiumWelcomeMessage.isActive
              ? props.userData.user.premiumWelcomeMessage.isActive
              : false,
        },

        // welcomeMessage: props.userData.user && props.userData.user.welcomeMessage
        //     ? state._setWelcomeMessge(props.userData.user.welcomeMessage, true)
        //     : null,

        welcomeMessage: {
          welcomeMessage:
            props.userData.user && props.userData.user.welcomeMessage
              ? state._setWelcomeMessge(
                  props.userData.user.welcomeMessage,
                  true
                )
              : null,
          plusWelcomeMessage:
            props.userData.user && props.userData.user.plusWelcomeMessage
              ? state._setWelcomeMessge(
                  props.userData.user.plusWelcomeMessage,
                  true
                )
              : null,
          premiumWelcomeMessage:
            props.userData.user && props.userData.user.premiumWelcomeMessage
              ? state._setWelcomeMessge(
                  props.userData.user.premiumWelcomeMessage,
                  true
                )
              : null,
        },

        postedData: {
          welcomeMessage:
            props.userData.user && props.userData.user.welcomeMessage
              ? state._setWelcomeMessge(props.userData.user.welcomeMessage)
              : defaultPostedData,
          plusWelcomeMessage:
            props.userData.user && props.userData.user.plusWelcomeMessage
              ? state._setWelcomeMessge(props.userData.user.plusWelcomeMessage)
              : defaultPostedData,
          premiumWelcomeMessage:
            props.userData.user && props.userData.user.premiumWelcomeMessage
              ? state._setWelcomeMessge(
                  props.userData.user.premiumWelcomeMessage
                )
              : defaultPostedData,
        },

        isFirstTime: false,
      };
    }

    return null;
  }

  _setWelcomeMessge = (data, _isContent = false) => {
    // const { postedData, isImage, isSound, isVideo } = deepClone(this.state);

    let welcomeMessage = data;
    let content = {
      url:
        welcomeMessage._content && welcomeMessage._content.url
          ? welcomeMessage._content.url
          : welcomeMessage.thumbnail,
      contentType: welcomeMessage.mediaType,
      contentId: welcomeMessage._content ? welcomeMessage._content : null,
    };
    // if (_isContent) {
    //   content["_content"] = welcomeMessage._content
    //     ? welcomeMessage._content
    //     : null;
    // }
    let postedData = {};
    postedData["uploadImages"] = [];
    if (content.url && content.contentType) {
      postedData["uploadImages"].push(content);
    }

    postedData["postPrice"] = welcomeMessage.amount;
    // postedData["plusPostPrice"] = welcomeMessage.plusPostPrice;
    // postedData["premiumPostPrice"] = welcomeMessage.premiumPostPrice;
    postedData["text"] = welcomeMessage.text;
    postedData["postType"] = "scheduled"; // just for activate price button
    // isImage = isSound = isVideo = false;
    // if (welcomeMessage.mediaType === "image") {
    //   isImage = true;
    // } else if (welcomeMessage.mediaType === "audio") {
    //   isSound = true;
    // } else if (welcomeMessage.mediaType === "video") {
    //   isVideo = true;
    // }

    return postedData;
    // this.setState({
    //   postedData,
    //   welcomeMessage,
    //   isImage,
    //   isSound,
    //   isVideo
    // });
  };

  _initializeForm = () => {
    let {
      postedData,
      isImage,
      isPoll,
      isSound,
      uploadImages,
      editMode,
      editedFeed,
      textContent,
      bankInfoModal,
    } = deepClone(this.state);

    isImage = {
      welcomeMessage: false,
      plusWelcomeMessage: false,
      premiumWelcomeMessage: false,
    };
    isSound = {
      welcomeMessage: false,
      plusWelcomeMessage: false,
      premiumWelcomeMessage: false,
    };
    isPoll = {
      welcomeMessage: false,
      plusWelcomeMessage: false,
      premiumWelcomeMessage: false,
    };

    editMode = false;
    editedFeed = null;

    uploadImages = {
      welcomeMessage: [],
      plusWelcomeMessage: [],
      premiumWelcomeMessage: [],
    };

    postedData = {
      welcomeMessage: {
        uploadImages: [],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        startDate: null,
        expirationPeriod: 0,
        postPrice: 0,
        // plusPostPrice: 0,
        // premiumPostPrice: 0,
        postType: "instant",
        endDate: null,
      },
      plusWelcomeMessage: {
        uploadImages: [],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        startDate: null,
        expirationPeriod: 0,
        postPrice: 0,
        // plusPostPrice: 0,
        // premiumPostPrice: 0,
        postType: "instant",
        endDate: null,
      },
      premiumWelcomeMessage: {
        uploadImages: [],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        startDate: null,
        expirationPeriod: 0,
        postPrice: 0,
        // plusPostPrice: 0,
        // premiumPostPrice: 0,
        postType: "instant",
        endDate: null,
      },
    };

    textContent = {
      welcomeMessage: null,
      plusWelcomeMessage: null,
      premiumWelcomeMessage: null,
    };

    this.setState({
      postedData,
      isImage,
      isPoll,
      isSound,
      editMode,
      editedFeed,
      uploadImages,
      textContent,
      bankInfoModal,
    });
    this.createPostRef.current._initializeIsTyped();

    this.postCostModalRef.current._initializeForm();
  };

  _toggle(activeTab) {
    this.setState({
      activeTab,
    });
  }

  _callEditApi = (data, isInitializeForm = false) => {
    return new Promise((resolve, reject) => {
      updateProfile(data)
        .then(async (res) => {
          if (isInitializeForm) this._initializeForm();

          this.props.hideLoader();
          showToast("Updated Successfully", "success");
          await this.props.fetchAndModifiedUserDetails();
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          this.props.hideLoader();
          showToast(
            err.reason && err.reason.length
              ? err.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        });
    });
  };

  //  for custom welcome message
  _onImageUpdate = (filesList, tierKeyName) => {
    let {
      uploadImages,
      postedData,
      // recordedAudio,
      // editMode,
      // editedFeed,
      isImage,
      isPoll,
      isSound,
      deletedMedias,
    } = deepClone(this.state);

    uploadImages[tierKeyName] = [];

    let flag = true;

    Array.from(filesList).forEach((each) => {
      if (each?.url) {
        uploadImages[tierKeyName].push(each);
      } else {
        if (
          each?.size <= UPLOAD_LIMIT ||
          each?.uploadData?.size <= UPLOAD_LIMIT
        ) {
          if (
            each?.type.includes("image") ||
            each?.type.includes("video") ||
            each?.type.includes("audio")
          ) {
            uploadImages[tierKeyName].push(each);
          }
        } else {
          if (flag) {
            showToast(
              `Files above ${UPLOAD_LIMIT_TEXT} are currently not supported`,
              "error"
            );
            flag = false;
          }
        }
      }

      isImage[tierKeyName] = true;
      isSound[tierKeyName] = isPoll[tierKeyName] = false;

      postedData[tierKeyName] = {
        uploadImages: uploadImages[tierKeyName],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        postPrice: postedData[tierKeyName]["postPrice"],
        // plusPostPrice: postedData["plusPostPrice"],
        // premiumPostPrice: postedData["premiumPostPrice"],
        endDate: postedData[tierKeyName]["endDate"],
        startDate: postedData[tierKeyName]["startDate"],
      };

      this.setState({
        postedData,
        isImage,
        isSound,
        isPoll,
        uploadImages,
        deletedMedias,
      });
    });
  };

  _openCostModal = (tierKeyName) => {
    if (!tierKeyName) {
      this._hideCostModal();
      return;
    }
    this.setState({
      showCostModal: {
        isOpen: true,
        openFor: tierKeyName,
      },
    });
  };

  _hideCostModal = () => {
    this.setState({
      showCostModal: {
        isOpen: false,
        openFor: null,
      },
    });
  };

  _toggleWelcomeMessageRelatedModal = (
    index,
    textContent = null,
    isPoll = false,
    tierKeyName
  ) => {
    if (!tierKeyName) return;

    switch (index) {
      case 0: {
        this._toggleSoundRecordModal(
          !this.state["showSoundRecordingModal"].isOpen,
          tierKeyName
        );
        break;
      }
      case 3: {
        this._openCostModal(tierKeyName);
        break;
      }
      default:
        break;
    }
  };

  _saveWelcomeMessagePostPrice = ({ basic }) => {
    const { postedData, showCostModal } = deepClone(this.state);
    postedData[showCostModal.openFor]["postPrice"] = basic;
    // postedData[showCostModal.openFor]["plusPostPrice"] = plus;
    // postedData[showCostModal.openFor]["premiumPostPrice"] = premium;
    this.setState({ postedData }, () => {
      this._hideCostModal();
    });
  };

  _saveSoundRecord = (data, tierKeyName, isModalOpened = true) => {
    let {
      postedData,
      isImage,
      isPoll,
      isSound,
      deletedMedias,
      showSoundRecordingModal,
    } = deepClone(this.state);

    let uploadSounds = [];

    if (isModalOpened) {
      tierKeyName = showSoundRecordingModal.openFor;
      this._toggleSoundRecordModal();
    } else {
      for (let i = 0; i < data.target.files.length; i++) {
        if (data.target.files[i].size <= UPLOAD_LIMIT) {
          let sound = data.target.files[i];
          sound.previewBlob = URL.createObjectURL(sound);
          sound.blob = sound;
          uploadSounds.push(sound);
        } else {
          showToast(
            `Files above ${UPLOAD_LIMIT_TEXT} are currently not supported`,
            "error"
          );
        }
      }
    }

    if (!tierKeyName) return;

    isImage[tierKeyName] = isPoll[tierKeyName] = false;
    isSound[tierKeyName] = true;

    deletedMedias[tierKeyName] = postedData[tierKeyName]["recordedAudio"]
      ? deletedMedias[tierKeyName].push(
          postedData[tierKeyName]["recordedAudio"]
        )
      : postedData[tierKeyName]["uploadImages"];

    postedData[tierKeyName] = {
      uploadImages: [],
      recordedAudio: isModalOpened ? data : uploadSounds[0],
      pollForm: {
        question: "",
        options: [],
        duration: 0,
      },
      postPrice: postedData[tierKeyName]["postPrice"],
      // plusPostPrice: postedData["plusPostPrice"],
      // premiumPostPrice: postedData["premiumPostPrice"],
      endDate: postedData[tierKeyName]["endDate"],
      startDate: postedData[tierKeyName]["startDate"],
      postType: "instant",
    };
    this.setState({
      postedData,
      isImage,
      isSound,
      isPoll,
      deletedMedias,
    });
  };

  _toggleSoundRecordModal = (isOpen = false, openFor = null) => {
    this.setState({
      showSoundRecordingModal: {
        isOpen,
        openFor,
      },
    });
  };

  _cancelSoundRecording = (tierKeyName) => {
    const { postedData, showSoundRecordingModal } = deepClone(this.state);

    if (!tierKeyName) {
      postedData[showSoundRecordingModal.openFor]["recordedAudio"] = null;
    } else {
      postedData[tierKeyName]["recordedAudio"] = null;
    }

    this.setState({
      postedData,
      showSoundRecordingModal: {
        isOpen: false,
        openFor: null,
      },
    });
  };

  _onProgressCallback = (uploadingPercentage) => {
    this.setState(
      {
        uploadingMessage: uploadingPercentage + " video uploading completed",
      },
      () => {
        this.props.showLoader(this.state.uploadingMessage);
      }
    );
  };

  _uploadToCloudinary = (img, imgType, imgName, contentType = "audio") => {
    return new Promise((resolve, reject) => {
      uploadPhotoToCloudinary(
        img,
        imgType,
        imgName,
        contentType,
        this._onProgressCallback
      )
        .then((url) => {
          resolve(url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  _postWelcomeMessageData = async (data = null, tierKeyName) => {
    // const { uploadingMessage } = deepClone(this.state);
    try {
      this.setState(
        {
          uploadingMessage: "Preparing your contents",
        },
        () => {
          this.props.showLoader(this.state.uploadingMessage);
        }
      );

      const { postedData, isImage, isSound, isPoll, editMode, textContent } =
        deepClone(this.state);

      let apiData = {
        category: isPoll[tierKeyName] ? "poll" : "general",
        text: isPoll[tierKeyName]
          ? postedData[tierKeyName]["pollForm"]["question"]
          : data.length
          ? data
          : null,
        description: isPoll[tierKeyName]
          ? textContent[tierKeyName] && textContent[tierKeyName].length
            ? textContent[tierKeyName]
            : null
          : null,
        options: isPoll[tierKeyName]
          ? postedData[tierKeyName]["pollForm"]["options"]
          : [],
        contents: isImage[tierKeyName]
          ? postedData[tierKeyName]["uploadImages"]
          : null,
        isPublic: isPoll[tierKeyName]
          ? true
          : postedData[tierKeyName].hasOwnProperty("postPrice")
          ? Number(postedData[tierKeyName].postPrice) === 0 ||
            !postedData[tierKeyName].postPrice
            ? true
            : false
          : true,
        price: isPoll[tierKeyName]
          ? 0
          : postedData[tierKeyName].postPrice
          ? postedData[tierKeyName].postPrice
          : 0,
        // plusPrice: isPoll ? 0 : postedData.plusPostPrice ? postedData.plusPostPrice : 0,
        // premiumPrice: isPoll ? 0 : postedData.premiumPostPrice ? postedData.premiumPostPrice : 0,
        postType: "instant",
        startDate: isPoll[tierKeyName]
          ? null
          : postedData[tierKeyName]["startDate"]
          ? moment(postedData[tierKeyName]["startDate"]).isBefore(moment())
            ? null
            : new Date(
                changeDateFormat(
                  convertDate(postedData[tierKeyName]["startDate"])
                )
              ).toISOString()
          : null,
        endDate: isPoll[tierKeyName]
          ? postedData[tierKeyName].pollForm.duration !== 0
            ? moment(
                moment()
                  .add(Number(postedData[tierKeyName].pollForm.duration), "day")
                  .endOf("day")
                  .format("YYYY-MM-DD")
              ).toISOString()
            : null
          : postedData[tierKeyName]["endDate"]
          ? new Date(
              changeDateFormat(convertDate(postedData[tierKeyName]["endDate"]))
            ).toISOString()
          : null,
      };
      // find out 'postType'
      if (apiData["startDate"] && apiData["endDate"]) {
        apiData["postType"] = "scheduled-temporary";
      } else if (apiData["startDate"] === null && apiData["endDate"] === null) {
        apiData["postType"] = "instant";
      } else if (apiData["startDate"]) {
        apiData["postType"] = "scheduled";
      } else if (apiData["endDate"]) {
        apiData["postType"] = "temporary";
      }

      if (apiData["postType"] === "scheduled") {
        delete apiData["endDate"];
        // editMode ? (apiData["endDate"] = null) : delete apiData["endDate"];
      } else if (apiData["postType"] === "temporary") {
        delete apiData["startDate"];
        // editMode ? (apiData["startDate"] = null) : delete apiData["startDate"];
      } else if (apiData["postType"] !== "scheduled-temporary") {
        delete apiData["startDate"];
        delete apiData["endDate"];
        // if (editMode) {
        //   apiData["endDate"] = null;
        //   apiData["startDate"] = null;
        // } else {
        //   delete apiData["startDate"];
        //   delete apiData["endDate"];
        // }
      }

      // for posting new feed
      if (!editMode) {
        if (!isPoll[tierKeyName]) {
          delete apiData["options"];

          if (isImage[tierKeyName]) {
            const filesToUpload = postedData[tierKeyName][
              "uploadImages"
            ]?.filter((each) => !each?.url);

            const libraryFiles = postedData[tierKeyName][
              "uploadImages"
            ]?.filter((each) => each?.url);

            let uploadedFiles = [];

            if (filesToUpload?.length) {
              const filesUploadedToServer = await uploadFileOnServer(
                filesToUpload
              );

              apiData["contents"] = filesUploadedToServer.map((file) => ({
                url: file.url,
                contentType: file?.contentType,
              }));

              // new files updated to "/content" api
              uploadedFiles = await saveContents(apiData);
            }

            if (uploadedFiles?.contents?.length) {
              apiData["contents"] = uploadedFiles.contents.concat(libraryFiles);
            } else {
              apiData["contents"] = libraryFiles;
            }

            this._saveContents(apiData, tierKeyName);
          } else if (isSound[tierKeyName]) {
            this._uploadToCloudinary(postedData[tierKeyName].recordedAudio.blob)
              .then((res) => {
                apiData["contents"] =
                  apiData["contents"] === null ? [] : apiData["contents"];
                apiData["contents"].push({ url: res, contentType: "audio" });
                this._saveContents(apiData, tierKeyName);
              })
              .catch((err) => {
                errorHandler(err);
              });
          } else {
            // when only content presents
            delete apiData["contents"];
            this._saveContents(apiData, tierKeyName);
          }
        } else {
          delete apiData["contents"];
          this._saveContents(apiData, tierKeyName);
        }
      }
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _saveContents = (apiData, tierKeyName) => {
    const { postedData } = deepClone(this.state);
    let data = {};

    data[tierKeyName] = {
      text: apiData.text,
      isPublic: apiData.hasOwnProperty("price") && apiData.price ? false : true,
      isActive: this.state.showWelcomeMessage[tierKeyName],
      amount:
        apiData.hasOwnProperty("price") && apiData.price ? apiData.price : null,
      // plusPostPrice:
      // apiData.hasOwnProperty("plusPrice") && apiData.plusPrice
      //   ? apiData.plusPrice
      //   : null,
      // premiumPostPrice:
      // apiData.hasOwnProperty("premiumPrice") && apiData.premiumPrice
      //   ? apiData.premiumPrice
      //   : null,
    };

    if (apiData.contents && apiData.contents.length) {
      apiData.contents.forEach((content) => {
        data[tierKeyName]["_content"] = content?._id;
        data[tierKeyName]["thumbnail"] = content?.url;
        data[tierKeyName]["mediaType"] = content?.contentType;
      });

      this._updateWelcomeMessage(data);
    } else if (
      postedData[tierKeyName].uploadImages &&
      postedData[tierKeyName].uploadImages.length
    ) {
      data[tierKeyName] = Object.assign(data[tierKeyName], {
        _content: postedData[tierKeyName].uploadImages[0].contentId,
        thumbnail: postedData[tierKeyName].uploadImages[0].thumbnail,
        mediaType: postedData[tierKeyName].uploadImages[0].contentType,
      });
      this._updateWelcomeMessage(data);
    } else {
      data[tierKeyName] = Object.assign(data[tierKeyName], {
        _content: null,
        thumbnail: null,
        amount: null,
      });
      this._updateWelcomeMessage(data);
    }
  };

  _updateWelcomeMessage = (data) => {
    this._callEditApi(data, true);
  };

  _deleteContent = (index, tierKeyName) => {
    let { postedData, uploadImages, isImage, isSound } = deepClone(this.state);

    // postedData["uploadImages"].splice(index, 1);
    if (postedData[tierKeyName]["recordedAudio"]) {
      postedData[tierKeyName]["recordedAudio"] = null;
    } else {
      postedData[tierKeyName]["uploadImages"].splice(index, 1);
    }
    uploadImages[tierKeyName] = postedData[tierKeyName]["uploadImages"];

    if (!postedData[tierKeyName]["uploadImages"]?.length)
      isImage[tierKeyName] = false;
    if (!postedData[tierKeyName]["recordedAudio"]) isSound[tierKeyName] = false;

    this.setState({ postedData, uploadImages, isImage, isSound });
  };

  _changeWelcomeMessageStatus = (tierKeyName) => {
    if (
      !this._canHavePermission("canUpdateSubscriptionSettings") ||
      !tierKeyName
    ) {
      return null;
    }

    const { showWelcomeMessage } = deepClone(this.state);
    const {
      userData: { user },
    } = this.props;

    if (
      !showWelcomeMessage[tierKeyName] &&
      (!user.hasOwnProperty(tierKeyName) ||
        (!user[tierKeyName].hasOwnProperty("_content") &&
          !user[tierKeyName].hasOwnProperty("text")))
    ) {
      showToast("Before turning on, save the welcome message first");
    } else {
      showWelcomeMessage[tierKeyName] = !showWelcomeMessage[tierKeyName];

      this.setState({ showWelcomeMessage }, () => {
        const data = {};
        data[tierKeyName] = { isActive: showWelcomeMessage[tierKeyName] };
        this._callEditApi(data);
      });
    }
  };

  _deleteCost = (fieldName, tierKeyName) => {
    const { postedData } = deepClone(this.state);
    postedData[tierKeyName][fieldName] = 0;
    this.setState({ postedData });
  };

  _canHavePermission = (permissionName) => {
    return isPermissionToAccess("profileAndSettings", permissionName, true);
  };

  render() {
    const {
      postedData,
      isImage,
      isSound,
      isPoll,
      editedFeed,
      editMode,
      showCostModal,
      showSoundRecordingModal,
      showWelcomeMessage,
      welcomeMessage,
      multiTierWelcomeMessageConfig,
      uploadImages,
    } = this.state;

    const { userData } = this.props;

    return (
      <>
        <div className="header_Settings" style={{ marginTop: 32 }}>
          <h4>Welcome Message</h4>
        </div>
        <p style={{ color: "#828282" }}>
          When turned ON this feature will send your customized welcome message
          to your new fanz. Additionally, you can choose to attach content with
          or without a price.
        </p>

        {this._canHavePermission("canUpdateSubscriptionSettings") ? (
          userData?.user?.multiTierSubscription ? (
            multiTierWelcomeMessageConfig.map(
              (multiTierEach, multiTierIndex) => (
                <div
                  className="settingOptionsWrap"
                  style={{ marginTop: 27 }}
                  key={multiTierIndex}
                >
                  <div className="header_Settings">
                    <h4>For {multiTierEach.label} Subscribers</h4>

                    <CustomInput
                      id={`custom_switch_settings_page_${multiTierEach.tierKeyName}_${multiTierIndex}`}
                      type="switch"
                      onChange={() =>
                        this._changeWelcomeMessageStatus(
                          multiTierEach.tierKeyName
                        )
                      }
                      checked={showWelcomeMessage[multiTierEach.tierKeyName]}
                      disabled={
                        !this._canHavePermission(
                          "canUpdateSubscriptionSettings"
                        )
                      }
                      label=""
                    />

                    {/* <div className="d-flex align-items-center">
                      {showWelcomeMessage[multiTierEach.tierKeyName] ? (
                        <span className="activeTxt">ON</span>
                      ) : (
                        <span className="inactiveTxt">OFF</span>
                      )}
                    </div> */}
                  </div>

                  <CreatePost
                    isForWelcomeMessage={true}
                    componentKey={multiTierEach.label}
                    ref={this.createPostRef}
                    isPostButtonEnabled={
                      (isImage[multiTierEach.tierKeyName] &&
                        postedData[multiTierEach.tierKeyName].uploadImages &&
                        postedData[multiTierEach.tierKeyName].uploadImages
                          .length) ||
                      (isSound[multiTierEach.tierKeyName] &&
                        postedData[multiTierEach.tierKeyName].recordedAudio)
                    }
                    isImage={isImage[multiTierEach.tierKeyName]}
                    isPoll={isPoll[multiTierEach.tierKeyName]}
                    isSound={isSound[multiTierEach.tierKeyName]}
                    onClick={(index, textContent, clickedPollModal) =>
                      this._toggleWelcomeMessageRelatedModal(
                        index,
                        textContent,
                        clickedPollModal,
                        multiTierEach["tierKeyName"]
                      )
                    }
                    selectedMedias={
                      postedData[multiTierEach.tierKeyName]["uploadImages"]
                    }
                    deleteCost={(fieldName) =>
                      this._deleteCost(fieldName, multiTierEach["tierKeyName"])
                    }
                    selectedAudio={
                      postedData[multiTierEach.tierKeyName]["recordedAudio"]
                    }
                    uploadFile={(e) =>
                      this._onImageUpdate(e, multiTierEach["tierKeyName"])
                    }
                    onPost={(data) =>
                      this._postWelcomeMessageData(
                        data,
                        multiTierEach["tierKeyName"]
                      )
                    }
                    postCost={
                      postedData[multiTierEach.tierKeyName]["postPrice"]
                    }
                    // plusPostPrice={postedData["plusPostPrice"]}
                    // premiumPostPrice={postedData["premiumPostPrice"]}
                    deleteContent={(index) =>
                      this._deleteContent(index, multiTierEach["tierKeyName"])
                    }
                    editMode={true}
                    editedFeed={welcomeMessage[multiTierEach.tierKeyName]}
                    userData={userData}
                    endDate={
                      isPoll[multiTierEach.tierKeyName]
                        ? postedData[multiTierEach.tierKeyName].pollForm
                            .duration !== 0
                          ? moment()
                              .add(
                                Number(
                                  postedData[multiTierEach.tierKeyName].pollForm
                                    .duration
                                ),
                                "day"
                              )
                              .endOf("day")
                              .format("YYYY-MM-DD, h:mm a")
                          : null
                        : postedData[multiTierEach.tierKeyName]["endDate"]
                        ? postedData[multiTierEach.tierKeyName]["endDate"]
                        : null
                    }
                    startDate={
                      isPoll[multiTierEach.tierKeyName]
                        ? null
                        : postedData[multiTierEach.tierKeyName]["startDate"]
                        ? convertDate(
                            postedData[multiTierEach.tierKeyName]["startDate"]
                          )
                        : null
                    }
                    onSoundSave={(data, isModalOpened) =>
                      this._saveSoundRecord(
                        data,
                        multiTierEach["tierKeyName"],
                        isModalOpened
                      )
                    }
                    onSoundCancel={() =>
                      this._cancelSoundRecording(multiTierEach["tierKeyName"])
                    }
                    isPollDisplay={false}
                    isScheduleDateDisplay={false}
                    isExpiryDateDisplay={false}
                    isCostDisplay={true}
                    isUpdateBtn={true}
                    updateBtnText={"Save"}
                    isMultipleUpload={false}
                    isMultipleImages={false}
                    isMultipleVideos={false}
                    isMultipleAudios={false}
                    hideFileNumber={true}
                    isUploadLimit={true}
                    isVideoUpload={true}
                    isAudioUpload={true}
                    isWelcomeMessage={true}
                    shouldUploadInModal={false}
                    uploadedFiles={[uploadImages[multiTierEach.tierKeyName]]}
                  />

                  <hr
                    style={{
                      marginTop: 15,
                      borderTopColor: "rgba(204, 204, 204, 0.5)",
                    }}
                  />
                </div>
              )
            )
          ) : (
            <div className="settingOptionsWrap" style={{ marginTop: 27 }}>
              <div className="header_Settings">
                <h4>Welcome Your Subscribers</h4>

                <CustomInput
                  id="custom_switch_settings_page_welcomeMessage"
                  type="switch"
                  onChange={() =>
                    this._changeWelcomeMessageStatus("welcomeMessage")
                  }
                  checked={showWelcomeMessage["welcomeMessage"]}
                  disabled={
                    !this._canHavePermission("canUpdateSubscriptionSettings")
                  }
                  label=""
                />

                {/* <div>
                  {showWelcomeMessage["welcomeMessage"] ? (
                    <span className="activeTxt">ON</span>
                  ) : (
                    <span className="inactiveTxt" style={{ color: "#ccc" }}>
                      OFF
                    </span>
                  )}
                </div> */}
              </div>

              <CreatePost
                isForWelcomeMessage={true}
                ref={this.createPostRef}
                isPostButtonEnabled={
                  (isImage["welcomeMessage"] &&
                    postedData["welcomeMessage"].uploadImages &&
                    postedData["welcomeMessage"].uploadImages.length) ||
                  (isSound["welcomeMessage"] &&
                    postedData["welcomeMessage"].recordedAudio)
                }
                isImage={isImage["welcomeMessage"]}
                isPoll={isPoll["welcomeMessage"]}
                isSound={isSound["welcomeMessage"]}
                onClick={(index, textContent, clickedPollModal) =>
                  this._toggleWelcomeMessageRelatedModal(
                    index,
                    textContent,
                    clickedPollModal,
                    "welcomeMessage"
                  )
                }
                selectedMedias={postedData["welcomeMessage"]["uploadImages"]}
                deleteCost={(fieldName) =>
                  this._deleteCost(fieldName, "welcomeMessage")
                }
                selectedAudio={postedData["welcomeMessage"]["recordedAudio"]}
                uploadFile={(e) => this._onImageUpdate(e, "welcomeMessage")}
                onPost={(data) =>
                  this._postWelcomeMessageData(data, "welcomeMessage")
                }
                postCost={postedData["welcomeMessage"]["postPrice"]}
                // plusPostPrice={postedData["plusPostPrice"]}
                // premiumPostPrice={postedData["premiumPostPrice"]}
                deleteContent={(index) =>
                  this._deleteContent(index, "welcomeMessage")
                }
                editMode={true}
                editedFeed={welcomeMessage["welcomeMessage"]}
                userData={userData}
                endDate={
                  isPoll["welcomeMessage"]
                    ? postedData["welcomeMessage"].pollForm.duration !== 0
                      ? moment()
                          .add(
                            Number(
                              postedData["welcomeMessage"].pollForm.duration
                            ),
                            "day"
                          )
                          .endOf("day")
                          .format("YYYY-MM-DD, h:mm a")
                      : null
                    : postedData["welcomeMessage"]["endDate"]
                    ? postedData["welcomeMessage"]["endDate"]
                    : null
                }
                startDate={
                  isPoll["welcomeMessage"]
                    ? null
                    : postedData["welcomeMessage"]["startDate"]
                    ? convertDate(postedData["welcomeMessage"]["startDate"])
                    : null
                }
                onSoundSave={(data, isModalOpened) =>
                  this._saveSoundRecord(data, "welcomeMessage", isModalOpened)
                }
                onSoundCancel={() =>
                  this._cancelSoundRecording("welcomeMessage")
                }
                isPollDisplay={false}
                isScheduleDateDisplay={false}
                isExpiryDateDisplay={false}
                isCostDisplay={true}
                isUpdateBtn={true}
                updateBtnText={"Save"}
                isMultipleUpload={false}
                hideFileNumber={true}
                isWelcomeMessage={true}
                isUploadLimit={true}
                isVideoUpload={true}
                isAudioUpload={true}
                shouldUploadInModal={false}
              />
            </div>
          )
        ) : null}

        <PostCostModal
          ref={this.postCostModalRef}
          price={editMode ? (editedFeed.price ? editedFeed.price : 0) : 0}
          userData={userData}
          isOpen={showCostModal.isOpen}
          type="welcome"
          onSave={({ basic }) => this._saveWelcomeMessagePostPrice({ basic })}
          onClose={() => this._hideCostModal()}
        />

        <SoundRecordUploadModal
          isOpen={showSoundRecordingModal.isOpen}
          onSave={(data) => this._saveSoundRecord(data)}
          onCancel={() => this._cancelSoundRecording()}
          uploadFile={(e) =>
            this._onImageUpdate(
              e?.target?.files,
              showSoundRecordingModal.openFor
            )
          }
          toggle={() => this._toggleSoundRecordModal()}
          isMultipleUpload={false}
          isHideUploadAudioFile
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    fetchAndModifiedUserDetails: () => dispatch(fetchAndModifiedUserDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionWelcomeMessage);
