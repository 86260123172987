/***********************  Dynamic Configuration  ************************/

// app version
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

// frontend deploy fan base url
export const FRONTEND_FAN_BASE_URL =
  process.env.REACT_APP_FRONTEND_FAN_BASE_URL;

// frontend deploy creator base url
export const FRONTEND_CREATOR_BASE_URL =
  process.env.REACT_APP_FRONTEND_CREATOR_BASE_URL;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ONE_SIGNAL_APP_ID = process.env.REACT_APP_ONE_SIGNAL_APP_ID;

export const CLOUDINARY_CONFIG = {
  API_KEY: process.env.REACT_APP_CLOUDINARY_CONFIG_API_KEY,
  UPLOAD_PRESET: process.env.REACT_APP_CLOUDINARY_CONFIG_UPLOAD_PRESET,
};

export const CAPTCHA_MICRO_SERVICE_BASE_URL =
  process.env.REACT_APP_CAPTCHA_MICRO_SERVICE_BASE_URL;

export const X_COIN_API_KEY = process.env.REACT_APP_X_COIN_API_KEY;

export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const SOCIAL_CREDENTIALS = {
  FACEBOOK_APP_ID: process.env.REACT_APP_SOCIAL_CREDENTIALS_FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_SOCIAL_CREDENTIALS_GOOGLE_CLIENT_ID,
};

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_IMAGE_BUCKET_NAME;

export const AWS_VIDEO_BUCKET_NAME =
  process.env.REACT_APP_AWS_VIDEO_BUCKET_NAME;

export const AWS_AUDIO_BUCKET_NAME =
  process.env.REACT_APP_AWS_AUDIO_BUCKET_NAME;

export const AWS_PUBLIC_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_PUBLIC_IMAGE_BUCKET_NAME;

export const AWS_DOC_BUCKET_NAME = process.env.REACT_APP_AWS_DOC_BUCKET_NAME;

export const AWS_S3_REGION = process.env.REACT_APP_S3_REGION;

/*********************  General values ***********************/
export const DEFAULT_PROFILE_PICTURE =
  process.env.REACT_APP_DEFAULT_PROFILE_PICTURE;
export const DEFAULT_GROUP_PICTURE =
  process.env.REACT_APP_DEFAULT_GROUP_PICTURE;

export const DEFAULT_COVER_PICTURE =
  process.env.REACT_APP_DEFAULT_COVER_PICTURE;

export const APP_LOGO = process.env.REACT_APP_LOGO;

export const APP_LOGO_ICON = process.env.REACT_APP_LOGO_ICON;

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_THEME_HEX_COLOR = process.env.REACT_APP_THEME_HEX_COLOR;

export const PAYMENT_GATEWAY = process.env.REACT_APP_PAYMENT_GATEWAY; // stripe // humboldt // square

export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

export const CURRENCY_TYPE = process.env.REACT_APP_CURRENCY_TYPE;

export const IP_FETCH_URL = process.env.REACT_APP_IP_FETCH_URL;

export const WELCOME_POST_IMAGE = process.env.REACT_APP_WELCOME_POST_IMAGE;

export const WELCOME_POST_VIDEO = process.env.REACT_APP_WELCOME_POST_VIDEO;

export const REQUEST_INVITE_VIDEO = process.env.REACT_APP_REQUEST_INVITE_VIDEO;

export const REQUEST_INVITE_THUMBNAIL =
  process.env.REACT_APP_REQUEST_INVITE_THUMBNAIL;

export const ACTIVE_TRIM_VIDEO = false;

export const APP_TUTORIAL_LINK = process.env.REACT_APP_APP_TUTORIAL_LINK;

export const INSTANT_PAYOUT_DEDUCT_PERCENTAGE = 1.5;

export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const TERMS_OF_SERVICE_URL = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
export const USC_2257_URL = process.env.REACT_APP_USC_2257_URL;
export const COMMUNITY_GUIDELINES_URL =
  process.env.REACT_APP_COMMUNITY_GUIDELINES_URL;
export const USER_DATA_DELETION_URL =
  process.env.REACT_APP_USER_DATA_DELETION_URL;
export const CONTACT_SUPPORT_URL = process.env.REACT_APP_CONTACT_SUPPORT_URL;
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;

/**
 * UPLOAD_LIMIT size in bytes,
 * upload limit upto 8 GB
 */
export const UPLOAD_LIMIT = 8 * 1024 * 1024 * 1024;
export const UPLOAD_LIMIT_TEXT = "8 GB";

/**
 * bio/introduction character limit
 * 1000 words
 */
export const BIO_MAX_LIMIT = 1000;

export const ADD_PROMOTION_COUPON_LINK =
  process.env.REACT_APP_ADD_PROMOTION_COUPON_LINK === "true" ? true : false;

export const SHOW_SOCIAL_ICON_ONLY = true; // if `false` then show full btn with text
