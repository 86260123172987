import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Table, Button } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import { getSubscribersStats } from "../http-calls";
import {
  errorHandler,
  formatCurrencyValue
} from "../helper-methods";
import SkeletonLoading from "../components/SkeletonLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FollowersStatistics = () => {
  const [followersRank, setFollowersRank] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const fansRankOnLast30Days = useMemo(() => {
    if (!followersRank?.length) return [];

    const filteredAndSortedList = followersRank
      ?.filter((each) => !each?.isLifetimeRank && each?.rankFor === "spend")
      ?.sort((fan1, fan2) => fan1?.rank - fan2.rank)
      ?.splice(0, 5);

    return filteredAndSortedList;
  }, [followersRank]);

  const _getFollowersStats = async () => {
    try {
      setLoading(true);
      const res = await getSubscribersStats();
      setFollowersRank(res?.ranks);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _getBadgeForRank = (rank) => {
    if (!rank) return <></>;

    switch (rank) {
      case 1:
        return (
          <img
            src={
              "https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594400/TF/diamond-supporter.gif"
            }
            alt="Diamond Supporter"
            className="fanBadge"
          />
        );

      case 2:
      case 3:
        return (
          <img
            src={
              "https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594400/TF/gold-supporter.gif"
            }
            alt="Gold Supporter"
            className="fanBadge"
          />
        );

      case 4:
      case 5:
        return (
          <img
            src={
              "https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594431/TF/silver-supporter.gif"
            }
            alt="Silver Supporter"
            className="fanBadge"
          />
        );

      default:
        <></>;
        break;
    }
  };

  useEffect(() => {
    _getFollowersStats();
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin bg-white pb-4">
          <Col xs={12} className="noPadding">
            <div className="pgTitle customMarginMob">
              <h2>Top Fans</h2>
            </div>

            <div className="referralHistoryTable followerStats">
              <Table data-test="team-members-table" responsive>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Amt. Spent</th>
                  </tr>
                </thead>

                <tbody>
                  {followersRank?.length ? (
                    fansRankOnLast30Days?.map((each) => (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            {_getBadgeForRank(each.rank)}
                            <span style={{ fontWeight: 600 }}>
                              {each?.rank}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              className="fanImg"
                              src={
                                each?._fan?.profilePicture ||
                                DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              alt="Profile Img"
                              loading="lazy"
                            />
                            <div className="ml-1">
                              {/* <p>{getFullName(each?._fan?.name)}</p> */}
                              {each?._fan?.username ? (
                                <Button
                                  color="link"
                                  style={{
                                    cursor: "pointer",
                                    color: "#5f42aa",
                                    fontSize: "13px",
                                    padding: 0,
                                  }}
                                  onClick={() =>
                                    history.push(
                                      `/my-profile?tabId=4&username=${each?._fan?.username}`
                                    )
                                  }
                                >
                                  @{each?._fan?.username}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <span
                            className="themeColor"
                            style={{ fontWeight: 500 }}
                          >
                            {formatCurrencyValue(each?.spendAmount)}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : loading ? (
                    <SkeletonLoading type={"table"} rows={1} col={3} />
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FollowersStatistics;
