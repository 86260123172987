import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatCurrencyValue,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import { useSelector } from "react-redux";
import { CURRENCY_SYMBOL } from "../config";
import {
  subscriptionPeriodConfig,
  tierMonthValidation,
  withOtherTierMonthValidation,
} from "../config/helper-config";
import CustomTooltip from "./custom/CustomTooltip";
import { createOrUpdate } from "../http-calls";
import { AmountConfig } from "../config/appConfig";

const TierComponent = ({ bundles, bundle, offers, onSave }) => {
  const influencer = useSelector((state) => state?.userData?.user);

  const canUpdateSubscriptionSettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateSubscriptionSettings",
      true
    );
  }, []);

  const [formFields, setFormFields] = useState({
    description: "",
    price: "",
    threeMonthsPrice: "",
    sixMonthsPrice: "",
    nineMonthsPrice: "",
    twelveMonthsPrice: "",
  });
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const promoOffersOfTier = useMemo(() => {
    return offers?.filter((each) => each?.tier === bundle.tier);
  }, [bundle, offers]);

  const _toggleCollapse = () => {
    setIsCollapseOpen((prev) => !prev);
  };

  const _errorMessage = (tier) => {
    switch (tier) {
      case "basic":
        return "Basic price should be lesser than plus and premium price of same period.";
      case "plus":
        return "Plus price should be greater than basic and less than premium of same period.";
      case "premium":
        return "premium price should be greater than basic and plus price of same period.";
      default:
        return "";
    }
  };

  const _checkMonthPriceWithInTier = (
    greaterCheckingArray = [],
    lesserCheckingArray = [],
    value
  ) => {
    let isValidLesser;
    let isValidGreater;

    const greaterArray = Object.keys(formFields)
      .map((fieldName) =>
        greaterCheckingArray.includes(fieldName) ? formFields[fieldName] : ""
      )
      .filter((value) => value.length);

    const lesserArray = Object.keys(formFields)
      .map((fieldName) =>
        lesserCheckingArray.includes(fieldName) ? formFields[fieldName] : ""
      )
      .filter((value) => value.length);

    isValidLesser = lesserArray.every(
      (price) => Number(price) >= Number(value)
    );
    isValidGreater = greaterArray.every(
      (price) => Number(price) <= Number(value)
    );

    return isValidLesser && isValidGreater;
  };

  const _checkMonthPriceWithOtherTier = (
    greaterCheckingTierArray = [],
    lesserCheckingTierArray = [],
    month,
    value
  ) => {
    console.log({
      greaterCheckingTierArray,
      lesserCheckingTierArray,
      month,
      value,
    });
    let isValidLesser;
    let isValidGreater;

    const lesserfiltersBundles = bundles
      .filter((bundle) => lesserCheckingTierArray.includes(bundle.tier))
      .filter((bundleObj) => bundleObj[month])
      .map((bundle) => bundle[month]);

    const greaterfiltersBundles = bundles
      .filter((bundle) => greaterCheckingTierArray.includes(bundle.tier))
      .filter((bundleObj) => bundleObj[month])
      .map((bundle) => bundle[month]);

    console.log(greaterfiltersBundles);

    isValidLesser = lesserfiltersBundles.every(
      (price) => Number(price) > Number(value)
    );
    isValidGreater = greaterfiltersBundles.every(
      (price) => Number(price) < Number(value)
    );

    return isValidLesser && isValidGreater;
  };

  const _getMinimumAddedPriceWithMonth = () => {
    if (bundle) {
      if (bundle.price)
        return (
          <h5>
            :&nbsp; {formatCurrencyValue(bundle.price)}
            <span style={{ fontWeight: 400 }}>/month</span>
          </h5>
        );
      else if (bundle.threeMonthsPrice)
        return (
          <h5>
            :&nbsp; {formatCurrencyValue(bundle.threeMonthsPrice)}
            <span style={{ fontWeight: 400 }}>/3 months</span>
          </h5>
        );
      else if (bundle.sixMonthsPrice)
        return (
          <h5>
            :&nbsp; {formatCurrencyValue(bundle.sixMonthsPrice)}
            <span style={{ fontWeight: 400 }}>/6 months</span>
          </h5>
        );
      else if (bundle.nineMonthsPrice)
        return (
          <h5>
            :&nbsp; {formatCurrencyValue(bundle.nineMonthsPrice)}
            <span style={{ fontWeight: 400 }}>/9 months</span>
          </h5>
        );
      else if (bundle.twelveMonthsPrice)
        return (
          <h5>
            :&nbsp; {formatCurrencyValue(bundle.twelveMonthsPrice)}
            <span style={{ fontWeight: 400 }}>/12 months</span>
          </h5>
        );
    }
    return null;
  };

  const _errorMessageWithInTier = (key) => {
    switch (key) {
      case "price": {
        return "*Price should be less than or equal to all other months price.";
      }
      case "threeMonthsPrice": {
        return "*Price should be greater than or equal to monthly price.";
      }
      case "sixMonthsPrice": {
        return "*Price should be greater than or equal to 1 and 3 months price.";
      }
      case "nineMonthsPrice": {
        return "*Price should be greater than or equal to 1, 3 and 6 months price.";
      }
      case "twelveMonthsPrice": {
        return "*Price should be greater than or equal to all other months price.";
      }

      default:
        return "Invalid Price.";
    }
  };

  const _validateBundlePriceWithPromoOffer = (key, newFormFields) => {
    const periodInNumber = subscriptionPeriodConfig[key];
    const offerWithLesserPrice = promoOffersOfTier?.find(
      (offer) =>
        offer?.subscriptionPeriod === periodInNumber &&
        offer?.price >= +newFormFields[key]
    );

    if (offerWithLesserPrice?._id) {
      return `${
        periodInNumber > 30 ? "Special Offer" : "Subscription"
      }  price should be higer than Promo Offer price of same period.`;
    } else {
      return null;
    }
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      const isFormPriceValid =
        newFormFields?.price?.trim()?.length ||
        newFormFields?.threeMonthsPrice?.trim()?.length ||
        newFormFields?.sixMonthsPrice?.trim()?.length ||
        newFormFields?.nineMonthsPrice?.trim()?.length ||
        newFormFields?.twelveMonthsPrice?.trim()?.length;

      Object.keys(newFormFields).forEach((key) => {
        if (!newIsDirty[key]) return;

        switch (key) {
          case "description": {
            if (newFormFields[key]?.trim().length) {
              if (isFormPriceValid) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Atleast one price is required";
                isFormValid = false;
              }
            } else {
              if (isFormPriceValid && influencer?.multiTierSubscription) {
                newErrors[key] = "*Required";
                isFormValid = false;
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
            }
            break;
          }

          case "price":
          case "threeMonthsPrice":
          case "sixMonthsPrice":
          case "nineMonthsPrice":
          case "twelveMonthsPrice": {
            if (!newFormFields?.[key]?.trim().length) {
              newErrors[key] = null;
              newIsDirty[key] = false;
              break;
            }

            if (
              (!isNaN(Number(newFormFields?.[key].trim())) &&
                +newFormFields?.[key] < +AmountConfig.minSubscriptionPrice) ||
              +newFormFields?.[key] > +AmountConfig.maximumLimit
            ) {
              newErrors[key] =
                +newFormFields?.[key] > +AmountConfig.maximumLimit
                  ? `*Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                  : `*Minimum ${CURRENCY_SYMBOL}${AmountConfig.minSubscriptionPrice}`;
              isFormValid = false;
              break;
            }

            // which months price need to check
            let greaterCheckingArray = tierMonthValidation["greater"][key];
            let lesserCheckingArray = tierMonthValidation["lesser"][key];

            if (
              // Monthly bundle price checking
              _checkMonthPriceWithInTier(
                greaterCheckingArray,
                lesserCheckingArray,
                newFormFields?.[key]
              )
            ) {
              let greaterCheckingTierArray =
                withOtherTierMonthValidation["greater"][bundle.tier];
              let lesserCheckingTierArray =
                withOtherTierMonthValidation["lesser"][bundle.tier];
              if (influencer?.multiTierSubscription) {
                if (
                  // Tier wise price checking
                  _checkMonthPriceWithOtherTier(
                    greaterCheckingTierArray,
                    lesserCheckingTierArray,
                    key,
                    newFormFields[key]
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = _errorMessage(bundle.tier);
                  isFormValid = false;
                  break;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
            } else {
              newErrors[key] = _errorMessageWithInTier(key);
              isFormValid = false;
              break;
            }

            if (promoOffersOfTier?.length) {
              newErrors[key] = _validateBundlePriceWithPromoOffer(
                key,
                newFormFields
              );

              if (newErrors[key]) {
                isFormValid = false;
                break;
              }
            }

            break;
          }
          default:
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm({ newFormFields, newIsDirty });
  };

  // Update from field value
  const _onChangeFormField = (key, value) => {
    if (!canUpdateSubscriptionSettings) {
      return;
    }
    const newFormFields = { ...formFields };

    // Check if contains valid number
    if (key !== "description") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }

    newFormFields[key] = value;
    setFormFields(newFormFields);

    if (!isFieldChanged) {
      setIsFieldChanged(true);
    }
  };

  // Shape bundle data into fromdata
  const _setFromFields = (bundle) => {
    const newFormFields = { ...formFields };

    newFormFields["bundle"] = bundle?.tier;
    newFormFields["description"] = bundle?.description || "";
    newFormFields["price"] =
      bundle?.price || bundle?.price === 0 ? bundle?.price?.toString() : "";
    newFormFields["sixMonthsPrice"] =
      bundle?.sixMonthsPrice || bundle?.sixMonthsPrice === 0
        ? bundle?.sixMonthsPrice?.toString()
        : "";
    newFormFields["threeMonthsPrice"] =
      bundle?.threeMonthsPrice || bundle?.threeMonthsPrice === 0
        ? bundle?.threeMonthsPrice?.toString()
        : "";
    newFormFields["nineMonthsPrice"] =
      bundle?.nineMonthsPrice || bundle?.nineMonthsPrice === 0
        ? bundle?.nineMonthsPrice?.toString()
        : "";
    newFormFields["twelveMonthsPrice"] =
      bundle?.twelveMonthsPrice || bundle?.twelveMonthsPrice === 0
        ? bundle?.twelveMonthsPrice?.toString()
        : "";

    setFormFields(newFormFields);
  };

  const _isOtherPriceExist = (tier = "basic") => {
    try {
      const basicBundle = bundles.find((e) => e.tier === "basic");
      const plusBundle = bundles.find((e) => e.tier === "plus");
      const premiumBundle = bundles.find((e) => e.tier === "premium");

      const isFormPriceValid =
        formFields.price?.trim().length ||
        formFields.threeMonthsPrice?.trim().length ||
        formFields.sixMonthsPrice?.trim().length ||
        formFields.nineMonthsPrice?.trim().length ||
        formFields.twelveMonthsPrice?.trim().length;

      const isBasicValid =
        basicBundle.price ||
        basicBundle.threeMonthsPrice ||
        basicBundle.sixMonthsPrice ||
        basicBundle.nineMonthsPrice ||
        basicBundle.twelveMonthsPrice;

      const isPlusValid =
        plusBundle.price ||
        plusBundle.threeMonthsPrice ||
        plusBundle.sixMonthsPrice ||
        plusBundle.nineMonthsPrice ||
        plusBundle.twelveMonthsPrice;

      const isPremiumValid =
        premiumBundle.price ||
        premiumBundle.threeMonthsPrice ||
        premiumBundle.sixMonthsPrice ||
        premiumBundle.nineMonthsPrice ||
        premiumBundle.twelveMonthsPrice;

      switch (tier) {
        case "basic": {
          return {
            isValid: isFormPriceValid ? true : false,
            reason: !isFormPriceValid
              ? `Atleast one ${
                  influencer.multiTierSubscription ? "basic " : ""
                }price is required`
              : null,
          };
        }
        case "plus": {
          return {
            isValid:
              (isFormPriceValid && isBasicValid) ||
              (!isFormPriceValid && !isPremiumValid)
                ? true
                : false,
            reason:
              !isFormPriceValid && isPremiumValid
                ? "Remove all premium prices to remove plus prices"
                : isFormPriceValid && !isBasicValid
                ? `Atleast one basic price is required`
                : null,
          };
        }
        case "premium": {
          return {
            isValid:
              (isFormPriceValid && isBasicValid && isPlusValid) ||
              (!isFormPriceValid && isBasicValid && isPlusValid)
                ? true
                : false,
            reason: !isBasicValid
              ? `Atleast one basic price is required`
              : !isPlusValid
              ? "Atleast one plus price is required"
              : null,
          };
        }
        default:
          return { isValid: false, reason: "Invalid data" };
      }
    } catch (error) {
      console.log("error>>", error);
      return { isValid: false, reason: "Invalid data" };
    }
  };

  // Submit and final call for passing updated data
  const _submitBundleData = async () => {
    try {
      setIsLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateForm({ newFormFields, newIsDirty });
      const { isValid, reason } = _isOtherPriceExist(bundle.tier);

      if (!isFormValid || !isValid) {
        setIsLoading(false);
        if (reason) showToast(reason, "error");
        return;
      }

      let data = {
        tier: newFormFields?.bundle,
        description: newFormFields?.description,
      };

      switch (data.tier) {
        case "basic": {
          if (newFormFields.price) {
            data["price"] = Number(newFormFields.price).toFixed(2);
          } else {
            data["price"] = "";
          }
          break;
        }
        case "plus": {
          if (newFormFields.price) {
            data["price"] = Number(newFormFields.price).toFixed(2);
          } else {
            data["price"] = "";
          }
          break;
        }
        case "premium": {
          if (newFormFields.price) {
            data["price"] = Number(newFormFields.price).toFixed(2);
          } else {
            data["price"] = "";
          }
          break;
        }
        default: {
          data = null;
          break;
        }
      }

      // if (newFormFields.price || newFormFields.price === 0) {
      //   data.price = Number(newFormFields.price).toFixed(2)
      // }

      if (data) {
        if (newFormFields.threeMonthsPrice) {
          data["threeMonthsPrice"] = Number(
            newFormFields.threeMonthsPrice
          ).toFixed(2);
        }

        if (newFormFields.sixMonthsPrice) {
          data["sixMonthsPrice"] = Number(newFormFields.sixMonthsPrice).toFixed(
            2
          );
        }

        if (newFormFields.nineMonthsPrice) {
          data["nineMonthsPrice"] = Number(
            newFormFields.nineMonthsPrice
          ).toFixed(2);
        }

        if (newFormFields.twelveMonthsPrice) {
          data["twelveMonthsPrice"] = Number(
            newFormFields.twelveMonthsPrice
          ).toFixed(2);
        }
      }

      await createOrUpdate(data);
      onSave();
      showToast("Tier Udpate Successfully", "success");
      setIsLoading(false);
      setIsFieldChanged(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (bundle) {
      _setFromFields(bundle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`tierInfoHead ${
          bundle.tier === "plus" ? "tierComponentHeadPlusBg" : ""
        } ${bundle.tier === "premium" ? "tierComponentHeadPremiumBg" : ""}`}
        onClick={() => _toggleCollapse()}
      >
        <div className="d-flex">
          <h5>
            {bundle.tier === "basic"
              ? influencer.multiTierSubscription
                ? capitalize(bundle.tier)
                : "Price"
              : bundle.tier
              ? capitalize(bundle.tier)
              : null}
          </h5>
          {_getMinimumAddedPriceWithMonth()}
        </div>
        <i
          className={`fa fa-chevron-right ${
            isCollapseOpen ? "iconCollapse" : ""
          }`}
        />
      </div>
      <Collapse isOpen={isCollapseOpen} className="tierInfoBody">
        <FormGroup>
          <Label>Subscription Price</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="dollarWrap">
                {CURRENCY_SYMBOL}/Month
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="price"
              type="text"
              disabled={!canUpdateSubscriptionSettings}
              value={formFields.price ? formFields.price : ""}
              onChange={(e) => _onChangeFormField("price", e.target.value)}
              onBlur={() => _onBlurFormfield("price")}
              className="dollarWrapInput"
            />
          </InputGroup>
          {errors?.price ? (
            <div className="form-error">{errors?.price}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Description</Label>
          <Input
            value={formFields.description ? formFields.description : ""}
            disabled={!canUpdateSubscriptionSettings}
            onChange={(e) => _onChangeFormField("description", e.target.value)}
            onBlur={() => _onBlurFormfield("description")}
            type="textarea"
            rows="3"
            name="description"
          />
          {errors?.description ? (
            <div className="form-error">{errors?.description}</div>
          ) : null}
        </FormGroup>

        <Label>
          Special Offer
          <sup
            id={`tier_component_Special_Offer_tooltip_${bundle.tier}`}
            className="infoIcon"
          >
            <i className="fa fa-info-circle" />
          </sup>
          <CustomTooltip
            text="Offer a larger amount of subscription time."
            target={`tier_component_Special_Offer_tooltip_${bundle.tier}`}
          />
        </Label>
        <Row className="noMargin">
          <Col xs={6} md={3} className="billCycleWrap">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  {CURRENCY_SYMBOL}/3 Months
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="threeMonthsPrice"
                type="text"
                disabled={!canUpdateSubscriptionSettings}
                value={
                  formFields.threeMonthsPrice ? formFields.threeMonthsPrice : ""
                }
                onChange={(e) =>
                  _onChangeFormField("threeMonthsPrice", e.target.value)
                }
                onBlur={() => _onBlurFormfield("threeMonthsPrice")}
                className="dollarWrapInput"
              />
              {errors.threeMonthsPrice ? (
                <div className="form-error">{errors.threeMonthsPrice}</div>
              ) : null}
            </InputGroup>
          </Col>
          <Col xs={6} md={3} className="billCycleWrap">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  {CURRENCY_SYMBOL}/6 Months
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="sixMonthsPrice"
                type="text"
                disabled={!canUpdateSubscriptionSettings}
                value={
                  formFields.sixMonthsPrice ? formFields.sixMonthsPrice : ""
                }
                onChange={(e) =>
                  _onChangeFormField("sixMonthsPrice", e.target.value)
                }
                onBlur={() => _onBlurFormfield("sixMonthsPrice")}
                className="dollarWrapInput"
              />
              {errors.sixMonthsPrice ? (
                <div className="form-error">{errors.sixMonthsPrice}</div>
              ) : null}
            </InputGroup>
          </Col>
          <Col xs={6} md={3} className="billCycleWrap">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  {CURRENCY_SYMBOL}/9 Months
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="nineMonthsPrice"
                type="text"
                disabled={!canUpdateSubscriptionSettings}
                value={
                  formFields.nineMonthsPrice ? formFields.nineMonthsPrice : ""
                }
                onChange={(e) =>
                  _onChangeFormField("nineMonthsPrice", e.target.value)
                }
                onBlur={() => _onBlurFormfield("nineMonthsPrice")}
                className="dollarWrapInput"
              />
              {errors.nineMonthsPrice ? (
                <div className="form-error">{errors.nineMonthsPrice}</div>
              ) : null}
            </InputGroup>
          </Col>
          <Col xs={6} md={3} className="billCycleWrap">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="dollarWrap">
                  {CURRENCY_SYMBOL}/12 Months
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="twelveMonthsPrice"
                type="text"
                disabled={!canUpdateSubscriptionSettings}
                value={
                  formFields.twelveMonthsPrice
                    ? formFields.twelveMonthsPrice
                    : ""
                }
                onChange={(e) =>
                  _onChangeFormField("twelveMonthsPrice", e.target.value)
                }
                onBlur={() => _onBlurFormfield("twelveMonthsPrice")}
                className="dollarWrapInput"
              />
              {errors.twelveMonthsPrice ? (
                <div className="form-error">{errors.twelveMonthsPrice}</div>
              ) : null}
            </InputGroup>
          </Col>
        </Row>

        {canUpdateSubscriptionSettings ? (
          <Button
            className="themeBtn saveBtn"
            onClick={() => _submitBundleData()}
            disabled={isLoading || !isFieldChanged}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Save
          </Button>
        ) : null}
      </Collapse>
    </>
  );
};

export default TierComponent;
