import { createSlice } from "@reduxjs/toolkit";
import {
  clearAllStories,
  deleteStoryFromRedux,
  getAndUpdateStories,
} from "../actions";

const initialState = {
  stories: [],
  loading: false,
};

const storiesDataSlice = createSlice({
  name: "storiesData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAllStories, (state, action) => {
        state.stories = [];
      })

      .addCase(deleteStoryFromRedux, (state, action) => {
        const tagIndex = state?.stories?.findIndex(
          (each) => each._id === action.payload?._id
        );

        if (tagIndex >= 0) {
          state.stories.splice(tagIndex, 1);
        }
      })

      .addCase(getAndUpdateStories.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateStories.fulfilled, (state, action) => {
        state.stories = action.payload;
        state.loading = false;
      });
  },
});

export const storiesReducer = storiesDataSlice.reducer;
