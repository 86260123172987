import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
  CustomInput,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
  DropdownItem,
} from "reactstrap";
import {
  deepClone,
  showToast,
  uploadFileOnServer,
  getLowResolutionLink,
  mediaFilesHandler,
  errorHandler,
  replaceTipText,
} from "../../helper-methods";
import { getMediaContents, saveContents } from "../../http-calls";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import SkeletonLoading from "../SkeletonLoading";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import {
  mediaLibraryFilterOptionsConfig,
  mediaLibrarySortByDurationConfig,
  mediaLibrarySortOptionsConfig,
} from "../../config/helper-config";
import CustomDateRangePicker from "../CustomDateRangePicker";
import moment from "moment";

class MediaLibraryModal extends Component {
  state = {
    activeTab: "4",
    imageContents: [],
    videoContents: [],
    audioContents: [],
    selectedImages: [],
    selectedVideos: [],
    selectedAudios: [],
    uploadMedias: [],
    contents: [],
    totalImages: 0,
    totalVideos: 0,
    totalAudios: 0,
    filter: {
      contentType: "null", //image, video,audio, null
      skip: 0,
      limit: 12,
      pageNumber: 1,
      dateRange: "allTime",
      duration: "allDuration",
      sort: "createdAt",
      dateRangeValue: "",
      startDate: "",
      endDate: "",
    },
    disabledSubmitBtn: false,
    loading: false,
    contentBreak: {
      image: {},
      video: {},
      audio: {},
    },
    isPreparingPreview: false,
    isFilterPopoverOpen: false,
  };

  // Toggle for Tab change
  _toggleTab = (activeTab = "4", contentType = null) => {
    let { filter } = deepClone(this.state);

    // console.log("CONTENT TYPE >>>>", contentType);

    if (contentType) {
      filter.contentType = contentType;
      filter.skip = 0;
      filter.pageNumber = 1;

      // if (contentType === "image") {
      //   filter["dateRange"] = "allTime";
      // }

      this.setState(
        {
          activeTab,
          filter,
          contentBreak: {
            image: {},
            video: {},
            audio: {},
          },
        },
        () => {
          this._fetchMediaContents();
        }
      );
    } else {
      filter.contentType = null;
      this.setState({
        activeTab,
        filter,
        isFilterPopoverOpen: false,
      });
    }
  };

  _closeThisModal = () => {
    this.props.toggle();
    this._resetState();
  };

  _resetState = () => {
    this.setState({
      activeTab: "4",
      selectedImages: [],
      selectedVideos: [],
      selectedAudios: [],
      uploadMedias: [],
      contents: [],
      disabledSubmitBtn: false,
      loading: false,
      contentBreak: {
        image: {},
        video: {},
        audio: {},
      },
      filter: {
        contentType: null,
        skip: 0,
        limit: 12,
        pageNumber: 1,
        dateRange: "allTime",
        duration: "allDuration",
        sort: "createdAt",
        dateRangeValue: "",
        startDate: "",
        endDate: "",
      },
      isFilterPopoverOpen: false,
    });
  };

  componentDidMount = () => {
    if (this.state.activeTab !== "4") {
      this._fetchMediaContents();
    } else {
      const { filter } = this.state;
      filter.contentType = null;
      this.setState({ filter });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.isOpen &&
      this.props.dropFileData?.length &&
      this.props.isOpen !== prevProps.isOpen
    ) {
      this._uploadFile(this.props.dropFileData);
    }
    if (
      this.props.isOpen &&
      this.props.changeMediaData &&
      this.props.isOpen !== prevProps.isOpen
    ) {
      if (this.props.changeMediaData?.contentType === "image") {
        this._toggleTab("1", "image");
      } else if (this.props.changeMediaData?.contentType === "video") {
        this._toggleTab("2", "video");
      } else if (this.props.changeMediaData?.contentType === "audio") {
        this._toggleTab("3", "audio");
      } else {
        this._toggleTab("4");
      }
    }
  };

  componentWillUnmount() {
    this._resetState();
  }

  // Fetch media for Image, Video and Audio
  _fetchMediaContents = async () => {
    let {
      imageContents,
      audioContents,
      videoContents,
      totalAudios,
      totalVideos,
      totalImages,
      filter,
    } = deepClone(this.state);
    console.log("filter", videoContents);
    // -------------this does not updates filter state---------------//
    if (filter.contentType === "image" || filter?.duration === "allDuration") {
      delete filter["duration"];
    }

    if (filter?.startDate && filter?.endDate) {
      filter["dateRange"] = `${moment(filter?.startDate).format(
        "DD/MM/YYYY"
      )}-${moment(filter?.endDate).format("DD/MM/YYYY")}`;

      delete filter["startDate"];
      delete filter["endDate"];
    } else {
      if (
        filter.dateRange === "customRange" ||
        filter?.dateRange === "allTime"
      ) {
        delete filter["dateRange"];
        delete filter["startDate"];
        delete filter["endDate"];
      }
    }

    delete filter["dateRangeValue"];
    // -------------this does not updates filter state---------------//

    try {
      let response;
      switch (filter.contentType) {
        case "image": {
          totalImages = 0;
          this.setState({ loading: true });
          response = await getMediaContents(filter);
          imageContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages =
            response.imageCount || totalImages
              ? response.imageCount || totalImages
              : 0;
          totalVideos =
            response.videoCount || totalVideos
              ? response.videoCount || totalVideos
              : 0;
          totalAudios =
            response.audioCount || totalAudios
              ? response.audioCount || totalAudios
              : 0;
          this.setState({
            imageContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {
              image: {},
              video: {},
              audio: {},
            },
          });
          break;
        }

        case "video": {
          totalVideos = 0;
          this.setState({ loading: true });
          response = await getMediaContents(filter);
          videoContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages =
            response.imageCount || totalImages
              ? response.imageCount || totalImages
              : 0;
          totalVideos =
            response.videoCount || totalVideos
              ? response.videoCount || totalVideos
              : 0;
          totalAudios =
            response.audioCount || totalAudios
              ? response.audioCount || totalAudios
              : 0;
          this.setState({
            videoContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {
              image: {},
              video: {},
              audio: {},
            },
          });
          break;
        }

        case "audio": {
          totalAudios = 0;
          this.setState({ loading: true });
          response = await getMediaContents(filter);
          // console.log("response", response);
          audioContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages =
            response.imageCount || totalImages
              ? response.imageCount || totalImages
              : 0;
          totalVideos =
            response.videoCount || totalVideos
              ? response.videoCount || totalVideos
              : 0;
          totalAudios =
            response.audioCount || totalAudios
              ? response.audioCount || totalAudios
              : 0;
          this.setState({
            audioContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {
              image: {},
              video: {},
              audio: {},
            },
          });
          break;
        }
        default:
      }
    } catch (error) {
      this.setState({ loading: false });

      console.log("error >>", error);
    }
  };

  _isValidUploadLimit = (
    uploadMedias = [],
    selectedImages = [],
    selectedVideos = [],
    selectedAudios = [],
    fileType
  ) => {
    return new Promise((resolve) => {
      const {
        imageLimit,
        videoLimit,
        audioLimit,
        uploadedFiles,
        isWelcomeMessage,
      } = this.props;

      const localImageCount = uploadMedias.filter((media) =>
        media?.type?.includes("image")
      ).length;
      const localVideoCount = uploadMedias.filter((media) =>
        media?.type?.includes("video")
      ).length;
      const localAudioCount = uploadMedias.filter((media) =>
        media?.type?.includes("audio")
      ).length;

      const uploadedImageCount = uploadedFiles?.filter((media) =>
        media?.contentType?.includes("image")
      ).length;
      const uploadedVideoCount = uploadedFiles?.filter((media) =>
        media?.contentType?.includes("video")
      ).length;
      const uploadedAudioCount = uploadedFiles?.filter((media) =>
        media?.contentType?.includes("audio")
      ).length;

      const totalImages =
        localImageCount + selectedImages.length + uploadedImageCount || 0;
      const totalVideos =
        localVideoCount + selectedVideos.length + uploadedVideoCount || 0;
      const totalAudios =
        localAudioCount + selectedAudios?.length + uploadedAudioCount || 0;

      if (isWelcomeMessage) {
        if (
          Number(totalImages) + Number(totalVideos) + Number(totalAudios) >
          2
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      }

      if (
        (fileType === "image" && totalImages > imageLimit) ||
        totalImages > imageLimit
      ) {
        resolve(false);
      } else if (
        (fileType === "video" && totalVideos > videoLimit) ||
        totalVideos > videoLimit
      ) {
        resolve(false);
      } else if (
        (fileType === "audio" && totalAudios > audioLimit) ||
        totalAudios > audioLimit
      ) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  _showLimitWarningToast = () => {
    const {
      imageLimit,
      videoLimit,
      audioLimit,
      changeMediaData,
      isWelcomeMessage,
      isImageUpload,
      isAudioUpload,
      isVideoUpload,
    } = this.props;
    if (isWelcomeMessage) {
      return showToast(
        `Only one ${isImageUpload ? "image" : ""} ${
          isVideoUpload ? "video" : ""
        } ${(isImageUpload || isVideoUpload) && isAudioUpload ? "or" : ""} ${
          isAudioUpload ? "audio" : ""
        } can be selected.`
      );
    }

    showToast(
      `Only ${
        imageLimit
          ? `${
              changeMediaData?.contentType === "image" &&
              changeMediaData?.index > -1
                ? imageLimit - 1
                : imageLimit
            } images`
          : ""
      } ${
        videoLimit
          ? `${
              changeMediaData?.contentType === "video" &&
              changeMediaData?.index > -1
                ? videoLimit - 1
                : videoLimit
            } video`
          : ""
      } ${
        audioLimit
          ? `and ${
              changeMediaData?.contentType === "audio" &&
              changeMediaData?.index > -1
                ? audioLimit - 1
                : audioLimit
            } audios`
          : ""
      } are allowed.`,
      "error"
    );
  };

  _handleFilesLimitValidation = (
    fileType,
    selectedImages,
    selectedVideos,
    selectedAudios
  ) => {
    return new Promise(async (resolve) => {
      const { uploadMedias } = this.state;

      const isValid = await this._isValidUploadLimit(
        uploadMedias,
        selectedImages,
        selectedVideos,
        selectedAudios,
        fileType
      );

      if (!isValid) {
        this._showLimitWarningToast();
        this.setState({ isPreparingPreview: false });
      }
      resolve(isValid);
    });
  };

  // Select media for each tab
  _toggleSelect = async (contentId, contentType) => {
    let {
      imageContents,
      audioContents,
      videoContents,
      selectedImages,
      selectedVideos,
      selectedAudios,
      uploadMedias,
    } = deepClone(this.state);

    const {
      isAudioUpload,
      isUploadLimit,
      isMultipleImages,
      isMultipleVideos,
      isMultipleAudios,
      isWelcomeMessage,
    } = this.props;

    switch (contentType) {
      case "image": {
        // Find selected Image
        let selectedImage = imageContents.find(
          (image) => image._id === contentId
        );

        if (selectedImages.find((image) => image._id === contentId)) {
          // Find selected image index in images array
          // and unselect
          let indexOfImage = selectedImages.findIndex(
            (image) => image._id === selectedImage._id
          );

          selectedImages.splice(indexOfImage, 1);

          if (isWelcomeMessage) {
            selectedVideos = [];
            selectedAudios = [];
            uploadMedias = [];
          }

          this.setState({
            imageContents,
            selectedImages,
            selectedVideos,
            selectedAudios,
            uploadMedias,
          });
          break;
        } else {
          // check for multi image select support option
          if (isMultipleImages) {
            selectedImages.push(selectedImage);
          } else {
            selectedImages.splice(0, 1, selectedImage);

            if (isWelcomeMessage) {
              selectedVideos = [];
              selectedAudios = [];
              uploadMedias = [];
            }
          }
        }

        if (isUploadLimit) {
          const isValid = await this._handleFilesLimitValidation(
            "image",
            selectedImages,
            selectedVideos,
            selectedAudios
          );

          if (!isValid) {
            return;
          }
        }

        this.setState({
          imageContents,
          selectedImages,
          selectedVideos,
          selectedAudios,
          uploadMedias,
        });
        break;
      }

      case "video": {
        // Find selected Video
        let selectedVideo = videoContents.find(
          (video) => video._id === contentId
        );

        if (selectedVideos.find((video) => video._id === contentId)) {
          // Find selected video index in videos array
          // and unselect
          let indexOfVideo = selectedVideos.findIndex(
            (video) => video._id === selectedVideo._id
          );

          selectedVideos.splice(indexOfVideo, 1);

          if (isWelcomeMessage) {
            selectedImages = [];
            selectedAudios = [];
            uploadMedias = [];
          }

          this.setState({
            // videoContents,
            selectedVideos,
            selectedImages,
            selectedAudios,
            uploadMedias,
          });
          break;
        } else {
          // check for multi videos select support option
          if (isMultipleVideos) {
            selectedVideos.push(selectedVideo);
          } else {
            selectedVideos.splice(0, 1, selectedVideo);

            if (isWelcomeMessage) {
              selectedImages = [];
              selectedAudios = [];
              uploadMedias = [];
            }
          }
        }

        if (isUploadLimit) {
          const isValid = await this._handleFilesLimitValidation(
            "video",
            selectedImages,
            selectedVideos,
            selectedAudios
          );
          if (!isValid) {
            return;
          }
        }

        this.setState({
          // videoContents,
          selectedVideos,
          selectedImages,
          selectedAudios,
          uploadMedias,
        });
        break;
      }

      case "audio": {
        if (!isAudioUpload) {
          showToast("Audio file not supported", "error");
          return;
        }

        // Find selected Audio
        let selectedAudio = audioContents.find(
          (audio) => audio._id === contentId
        );

        if (selectedAudios.find((audio) => audio._id === contentId)) {
          // Find selected audio index in audios array
          // and unselect
          let indexOfAudio = selectedAudios.findIndex(
            (audio) => audio._id === selectedAudio._id
          );

          selectedAudios.splice(indexOfAudio, 1);

          if (isWelcomeMessage) {
            selectedImages = [];
            selectedVideos = [];
            uploadMedias = [];
          }
          this.setState({
            audioContents,
            selectedAudios,
            selectedImages,
            selectedVideos,
            uploadMedias,
          });
          break;
        } else {
          // check for multi audios select support option
          if (isMultipleAudios) {
            selectedAudios.push(selectedAudio);
          } else {
            selectedAudios.splice(0, 1, selectedAudio);
            if (isWelcomeMessage) {
              selectedImages = [];
              selectedVideos = [];
              uploadMedias = [];
            }
          }
        }

        if (isUploadLimit) {
          const isValid = await this._handleFilesLimitValidation(
            "audio",
            selectedImages,
            selectedVideos,
            selectedAudios
          );
          if (!isValid) {
            return;
          }
        }

        this.setState({
          audioContents,
          selectedAudios,
          selectedImages,
          selectedVideos,
          uploadMedias,
        });
        break;
      }
      default:
    }
  };

  // media content watermarked and craete blob
  _uploadFile = async (files) => {
    let { uploadMedias, selectedImages, selectedVideos, selectedAudios } =
      deepClone(this.state);

    const {
      isAudioUpload,
      isImageUpload,
      isVideoUpload,
      isPdfUpload,
      isUploadLimit,
      isWelcomeMessage,
    } = this.props;

    let fileList = Array.from(files);

    if (!fileList?.length) return;

    if (isWelcomeMessage && (isImageUpload || isVideoUpload || isAudioUpload)) {
      if (
        selectedImages?.length ||
        selectedVideos?.length ||
        selectedAudios?.length ||
        uploadMedias?.length
      ) {
        showToast(
          `Only one ${isImageUpload ? "image, " : ""} ${
            isVideoUpload ? "video, " : ""
          } ${isAudioUpload ? "audio" : ""} file is allowed.`,
          "error"
        );
        return;
      }
    }

    this.setState({ isPreparingPreview: true });

    let imageToast,
      videoToast,
      audioToast = false;

    for (let i = 0; i < fileList?.length; i++) {
      if (!isImageUpload && fileList?.[i]?.type?.includes("image")) {
        if (!imageToast) {
          imageToast = true;
          showToast("Image files are not allowed.", "error");
          this.setState({ isPreparingPreview: false });
        }
        continue;
      } else if (!isVideoUpload && fileList?.[i]?.type?.includes("video")) {
        if (!videoToast) {
          videoToast = true;
          showToast("Video files are not allowed.", "error");
          this.setState({ isPreparingPreview: false });
        }
        continue;
      } else if (!isAudioUpload && fileList?.[i]?.type?.includes("audio")) {
        if (!audioToast) {
          audioToast = true;
          showToast("Audio files are not allowed.", "error");
          this.setState({ isPreparingPreview: false });
        }
        continue;
      }
    }

    if (imageToast || videoToast || audioToast) {
      return;
    }

    if (isUploadLimit) {
      const isValid = await this._isValidUploadLimit(
        [...fileList, ...uploadMedias],
        selectedImages,
        selectedVideos,
        selectedAudios
      );

      if (!isValid) {
        this._showLimitWarningToast();
        this.setState({ isPreparingPreview: false });
        return;
      }
    }

    const mediaPreviewRes = await mediaFilesHandler({
      newFiles: fileList,
      oldFiles: uploadMedias,
      isAudioUpload,
      isPdfUpload,
      pageName: "MediaLibraryModal",
    });

    this.setState({ isPreparingPreview: false });

    if (mediaPreviewRes?.error) {
      errorHandler();
      return;
    }

    this.setState({ uploadMedias: mediaPreviewRes?.mediaFiles });
  };

  _onLoadedMetadata = (target, index) => {
    let { uploadMedias } = deepClone(this.state);
    uploadMedias[index]["duration"] = target.duration;
    this.setState({ uploadMedias });
  };

  _deleteContent = (index) => {
    let { uploadMedias } = deepClone(this.state);
    uploadMedias.splice(index, 1);
    this.setState({ uploadMedias });
  };

  // Merge all content in one array and pass to parent component
  _createContentsData = (data = []) => {
    let { contents } = deepClone(this.state);
    contents = contents.concat(data);

    this.setState({ contents, disabledSubmitBtn: false }, () => {
      this.props.uploadContents(contents);
      this._closeThisModal();
    });
  };

  _saveContents = async (contents = []) => {
    try {
      const res = await saveContents({ contents });

      const uploadedContents = res.contents.map((content) => {
        return {
          contentType: content.contentType,
          url: content.url,
          hlsLink: content.hls,
          thumbnail: content.thumbnail,
          _id: content._id,
          extension: content.extension,
          isNew: true,
        };
      });

      this._createContentsData(uploadedContents);
    } catch (error) {
      errorHandler(error);
    }
  };

  // Upload media in aws s3 and get url
  _uploadMedias = async () => {
    let { uploadMedias } = deepClone(this.state);

    const response = await uploadFileOnServer(uploadMedias);

    console.log("response>>", response);

    this._saveContents(response);
  };

  _submitSelectedMedia = async () => {
    let {
      contents,
      selectedImages,
      selectedVideos,
      selectedAudios,
      uploadMedias,
    } = deepClone(this.state);

    this.setState({ disabledSubmitBtn: true }, () => {
      this.props.toggle();
    });

    // Concat all types of media in contents array
    contents = selectedImages
      .concat(selectedVideos)
      .concat(selectedAudios)
      .map((media) => ({
        contentType: media.contentType,
        url: media.url,
        hlsLink: media.hls,
        thumbnail: media.thumbnail,
        _id: media._id,
        duration: media?.duration || null,
      }));

    if (!this.props.shouldUploadInModal) {
      if (this.state.uploadMedias?.length) {
        this.props.uploadContents(uploadMedias.concat(contents));
      } else {
        this.props.uploadContents(contents);
      }
      this._closeThisModal();
    } else {
      this.setState({ contents }, async () => {
        if (this.state.uploadMedias?.length) {
          // if user upload content for the first time
          this._uploadMedias();
        } else {
          // if user not upload content
          this._createContentsData();
        }
      });
    }
  };

  _handlePageChange = (pageNum) => {
    let { filter } = deepClone(this.state);
    filter["pageNumber"] = pageNum;
    filter["skip"] = (pageNum - 1) * filter["limit"];
    this.setState({ filter }, () => {
      this._fetchMediaContents();
    });
  };

  _getMediaNumber = (type) => {
    const { uploadMedias } = this.state;
    return uploadMedias.filter((media) => media?.type?.includes(type)).length;
  };

  _getTotalItem = () => {
    let totalItem;
    switch (this.state.filter.contentType) {
      case "image":
        totalItem = this.state.totalImages;
        break;
      case "video":
        totalItem = this.state.totalVideos;
        break;
      case "audio":
        totalItem = this.state.totalAudios;
        break;
      default:
        totalItem = 0;
        break;
    }
    return totalItem;
  };

  _onErrorContent = (type = "", index = 0, isError = false) => {
    try {
      const { contentBreak } = this.state;
      contentBreak[type][index] = isError;
      this.setState({ contentBreak });
    } catch (error) {
      console.log({ error });
    }
  };

  _isContentSelected = (contentId, type) => {
    if (!contentId || !type) return false;

    const { selectedImages, selectedVideos } = this.state;

    switch (type) {
      case "image": {
        return selectedImages.find((each) => each?._id === contentId)
          ? true
          : false;
      }

      case "video": {
        return selectedVideos.find((each) => each?._id === contentId)
          ? true
          : false;
      }
      default:
        return false;
    }
  };

  _isAnyMediaPicked = () => {
    const { selectedImages, selectedVideos, selectedAudios, uploadMedias } =
      this.state;
    return (
      selectedImages?.length ||
      selectedVideos?.length ||
      selectedAudios?.length ||
      uploadMedias?.length
    );
  };

  _onChangeFilterAndSort = (key, value) => {
    const { filter } = this.state;

    filter[key] = value;

    if (key === "dateRange") {
      this._onDatesChange({});
      return;
    }
    if (key === "duration") {
      // console.log(filter["duration"]);
      // console.log("duration", value);
      filter.pageNumber = 1;
      filter.skip = 0;
    }
    // if (key === "dateRange" && value === "customRange") {
    //   this.setState({ isFilterPopoverOpen: false });
    // }

    this.setState({ filter }, () => this._fetchMediaContents());
  };

  // handles date change
  _onDatesChange = ({ startDate, endDate }) => {
    const { filter } = this.state;

    switch (filter.dateRange) {
      case "customRange": {
        if (startDate || endDate) {
          filter.startDate = startDate ? moment(startDate) : null;
          filter.endDate = endDate ? moment(endDate) : null;
        } else {
          delete filter["startDate"];
          delete filter["endDate"];
        }
        break;
      }

      case "last7Days": {
        filter.startDate = moment().subtract(6, "d");
        filter.endDate = moment();
        break;
      }

      case "last30days": {
        filter.startDate = moment().subtract(30, "d");
        filter.endDate = moment();
        break;
      }

      case "last90days": {
        filter.startDate = moment().subtract(90, "d");
        filter.endDate = moment();
        break;
      }

      case "allTime": {
        filter.startDate = null;
        filter.endDate = null;
        filter["dateRange"] = "allTime";
        break;
      }

      default:
        filter.startDate = null;
        filter.endDate = null;
        filter["dateRange"] = "allTime";
        break;
    }
    if ((startDate && endDate) || (!startDate && !endDate)) {
      filter.pageNumber = 1;
      filter.skip = 0;
    }
    this.setState({ filter }, () => {
      if ((startDate && endDate) || (!startDate && !endDate)) {
        this._fetchMediaContents();
      }
    });
  };

  render() {
    const {
      imageContents,
      audioContents,
      videoContents,
      selectedImages,
      selectedVideos,
      selectedAudios,
      totalAudios,
      totalVideos,
      totalImages,
      uploadMedias,
      disabledSubmitBtn,
      filter,
      loading,
      activeTab,
      contentBreak,
      isPreparingPreview,
      isFilterPopoverOpen,
    } = this.state;

    const countLocalUploadedFile =
      selectedImages?.length +
      selectedVideos?.length +
      selectedAudios?.length +
      uploadMedias?.length;

    const {
      isAudioUpload,
      isVideoUpload,
      isImageUpload,
      isPdfUpload,
      isMultipleUpload,
    } = this.props;

    const MediaLibraryLoading = (
      <>
        {[...Array(12)].map((_, index) => (
          <Col
            xs={6}
            md={4}
            lg={3}
            className="px-1"
            key={`media_video_loading_${index}`}
            style={{ marginBottom: 4 }}
          >
            <SkeletonLoading type="box" count={1} width={"100%"} height={152} />
          </Col>
        ))}
      </>
    );

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeThisModal()}
        className="modal-dialog-centered modal-xl modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeThisModal()}>
          Media Library
        </ModalHeader>

        <ModalBody>
          <div className="d-flex justify-content-end">
            {filter?.contentType && filter?.dateRange === "customRange" ? (
              <div className="mr-2">
                <CustomDateRangePicker
                  startDate={filter?.startDate || null}
                  endDate={filter?.endDate || null}
                  startDateId={`media_lib_startDate_filter`}
                  endDateId={`media_lib_endDate_filter`}
                  onDatesChange={({ startDate, endDate }) =>
                    this._onDatesChange({
                      startDate,
                      endDate,
                    })
                  }
                  isDisabledOutsideRange={true}
                />
              </div>
            ) : null}

            {filter?.contentType ? (
              <div>
                <Button
                  id="media_library_filter"
                  className="customPopoverBtn"
                  onClick={() =>
                    this.setState({ isFilterPopoverOpen: !isFilterPopoverOpen })
                  }
                >
                  <img
                    src={"/assets/img/filter-icon.png"}
                    alt="Filter"
                    loading="lazy"
                  />
                </Button>

                <UncontrolledPopover
                  className="customPopover"
                  placement="left"
                  target="media_library_filter"
                  trigger="legacy"
                  isOpen={isFilterPopoverOpen}
                  toggle={() =>
                    this.setState({ isFilterPopoverOpen: !isFilterPopoverOpen })
                  }
                >
                  <PopoverBody>
                    {mediaLibraryFilterOptionsConfig.map((each, index) => (
                      <FormGroup check key={each.value} className="radio">
                        <CustomInput
                          className="form-check-input"
                          id={`media_lib_activeFilterOption_${each.value}`}
                          type="radio"
                          name="media_lib_activeFilterOption"
                          checked={filter.dateRange === each.value}
                          value={each.value}
                          onChange={() =>
                            this._onChangeFilterAndSort("dateRange", each.value)
                          }
                          label={each.label}
                        />
                      </FormGroup>
                    ))}

                    {filter?.contentType === "video" ||
                    filter?.contentType === "audio" ? (
                      <>
                        <DropdownItem divider />

                        {mediaLibrarySortByDurationConfig.map((each, index) => (
                          <FormGroup check key={each.value} className="radio">
                            <CustomInput
                              className="form-check-input"
                              id={`media_lib_activeSortByDuration_${each.value}`}
                              type="radio"
                              name="media_lib_activeSortByDuration"
                              checked={filter.duration === each.value}
                              value={each.value}
                              onChange={() =>
                                this._onChangeFilterAndSort(
                                  "duration",
                                  each.value
                                )
                              }
                              label={replaceTipText(each.label)}
                            />
                          </FormGroup>
                        ))}
                      </>
                    ) : null}

                    <DropdownItem divider />

                    {mediaLibrarySortOptionsConfig.map((each, index) => (
                      <FormGroup check key={each.value} className="radio">
                        <CustomInput
                          className="form-check-input"
                          id={`media_lib_activeSortOption_${each.value}`}
                          type="radio"
                          name="media_lib_activeSortOption"
                          checked={filter.sort === each.value}
                          value={each.value}
                          onChange={() =>
                            this._onChangeFilterAndSort("sort", each.value)
                          }
                          label={replaceTipText(each.label)}
                        />
                      </FormGroup>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            ) : null}
          </div>

          <Nav tabs className="customTabs mediaLibraryTab">
            {isImageUpload ? (
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => {
                    this._toggleTab("1", "image");
                  }}
                >
                  Photos{" "}
                  {totalImages ? <span>{`(${totalImages})`}</span> : null}
                </NavLink>
              </NavItem>
            ) : null}

            {isVideoUpload ? (
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => {
                    this._toggleTab("2", "video");
                  }}
                >
                  Videos{" "}
                  {totalVideos ? <span>{`(${totalVideos})`}</span> : null}
                </NavLink>
              </NavItem>
            ) : null}

            {isAudioUpload ? (
              <NavItem>
                {console.log(totalAudios)}
                <NavLink
                  active={activeTab === "3"}
                  onClick={() => {
                    this._toggleTab("3", "audio");
                  }}
                >
                  Audio {totalAudios ? <span>{`(${totalAudios})`}</span> : null}
                </NavLink>
              </NavItem>
            ) : null}

            <NavItem>
              <NavLink
                active={activeTab === "4"}
                onClick={() => {
                  this._toggleTab("4");
                }}
              >
                Upload New
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="tabContentMediaLibrary">
            {isImageUpload ? (
              <TabPane tabId="1">
                <Row className="mx-0">
                  {imageContents?.length ? (
                    imageContents.map((image, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="px-1">
                        {/* selected file will look like this */}
                        {contentBreak?.image?.[index] ? (
                          <div className="mediaLibraryFilesWrap">
                            {/* if the media is not loading */}
                            <Button
                              className="reloadBtn"
                              onClick={() =>
                                this._onErrorContent("image", index, false)
                              }
                            >
                              <i className="fa fa-refresh" />
                            </Button>
                          </div>
                        ) : (
                          <div
                            className="mediaLibraryFilesWrap"
                            onClick={() =>
                              this._toggleSelect(image._id, "image")
                            }
                          >
                            <img
                              src={getLowResolutionLink(image?.url)}
                              alt="Media File"
                              className="mediaLibraryFiles"
                              onError={() =>
                                this._onErrorContent("image", index, true)
                              }
                              loading="lazy"
                            />
                            {selectedImages.find(
                              (selectedImage) => selectedImage._id === image._id
                            ) ? (
                              <div className="mediaFileSelected">
                                <i className="fa fa-check-circle" />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </Col>
                    ))
                  ) : loading ? (
                    MediaLibraryLoading
                  ) : (
                    <Col xs={12}>
                      <div className="noContentFound">Image not found</div>
                    </Col>
                  )}
                </Row>
              </TabPane>
            ) : null}

            {isVideoUpload ? (
              <TabPane tabId="2">
                {/* removed className videoMediaLibrary from Row to view controls */}
                <Row className="mx-0 videoMediaLibrary">
                  {videoContents?.length ? (
                    videoContents.map((video, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="px-1">
                        {contentBreak?.video?.[index] ? (
                          <div className="mediaLibraryFilesWrap">
                            {/* if the media is not loading */}
                            <Button
                              className="reloadBtn"
                              onClick={() =>
                                this._onErrorContent("video", index, false)
                              }
                            >
                              <i className="fa fa-refresh" />
                            </Button>
                          </div>
                        ) : (
                          <>
                            <div className="mediaLibraryFilesWrap">
                              <CustomVideoPlayer
                                videoClassName="mediaLibraryFiles"
                                thumbnail={video?.thumbnail}
                                src={video?.url}
                                hlsLink={video?.hls}
                                feedId={video?._id}
                                duration={video?.duration}
                                muted
                                controls
                                showMinimalControl={true}
                              />

                              {/* <div className="selectVideo">
                                <i className="fa fa-play" />
                              </div> */}

                              <CustomInput
                                type="checkbox"
                                id={`selectVideo_${video._id}`}
                                label=""
                                checked={this._isContentSelected(
                                  video?._id,
                                  "video"
                                )}
                                className="selectVideoCheckbox"
                                onChange={() =>
                                  this._toggleSelect(video._id, "video")
                                }
                              />

                              {/* {selectedVideos.find(
                                (selectedVideo) =>
                                  selectedVideo._id === video._id
                              ) ? (
                                <div className="mediaFileSelected">
                                  <i className="fa fa-check-square" />
                                </div>
                              ) : null} */}
                            </div>
                          </>
                        )}
                      </Col>
                    ))
                  ) : loading ? (
                    MediaLibraryLoading
                  ) : (
                    <Col xs={12}>
                      <div className="noContentFound">Video not found</div>
                    </Col>
                  )}
                </Row>
              </TabPane>
            ) : null}

            {isAudioUpload ? (
              <TabPane tabId="3">
                <Row className="mx-0">
                  {audioContents?.length ? (
                    audioContents.map((audio, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="px-1">
                        <div
                          className="mediaLibraryFilesWrap"
                          onClick={() => this._toggleSelect(audio._id, "audio")}
                        >
                          <div className="mediaLibraryFiles d-flex align-items-center justify-content-center">
                            <audio
                              ref="audioSource"
                              controls="controls"
                              src={audio.url}
                            />
                            {selectedAudios.find(
                              (selectedAudio) => selectedAudio._id === audio._id
                            ) ? (
                              <div className="mediaFileSelected">
                                <i className="fa fa-check-circle" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : loading ? (
                    MediaLibraryLoading
                  ) : (
                    <Col xs={12}>
                      <div className="noContentFound">Audio not found</div>
                    </Col>
                  )}
                </Row>
              </TabPane>
            ) : null}

            <TabPane tabId="4">
              <div className="mediaFileUploadWrap">
                <Label className="btn themeBtn">
                  <input
                    type="file"
                    accept={`
                    ${isImageUpload ? "image/*, .heif, .heic," : ""}
                    ${isVideoUpload ? "video/*," : ""},
                    ${isAudioUpload ? "audio/*," : ""},
                    ${isPdfUpload ? ".pdf," : ""}`}
                    multiple={
                      !this.props.hasOwnProperty("isMultipleUpload") ||
                      (this.props.hasOwnProperty("isMultipleUpload") &&
                        isMultipleUpload)
                    }
                    onChange={(e) => {
                      this._uploadFile(e.target.files);
                      e.target.value = null;
                    }}
                    style={{ display: "none" }}
                    disabled={isPreparingPreview}
                  />
                  {isPreparingPreview ? (
                    <i className="fa fa-spinner fa-spin mr-1 clearSearch" />
                  ) : (
                    <span>Upload</span>
                  )}
                </Label>
              </div>

              <div className="msgPreviewWrap mt-2">
                {uploadMedias && uploadMedias.length
                  ? uploadMedias.map((media, index) =>
                      media?.type?.split("/")[0] === "application" ||
                      media?.type?.split("/")[0] === "image" ||
                      media?.type?.split("/")[0] === "audio" ? (
                        <div className="msgPreview" key={index}>
                          {media?.type?.split("/")[0] === "application" ? (
                            <img
                              src="/assets/img/pdf_icon.png"
                              alt="PDF"
                              style={{ height: 28, width: "unset" }}
                              loading="lazy"
                            />
                          ) : media?.type?.split("/")[0] === "audio" ? (
                            <img
                              src="/assets/img/microphone.png"
                              alt="Microphone"
                              style={{ height: 28, width: "unset" }}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              key={index}
                              src={media["previewBlob"]}
                              alt="Preview"
                              loading="lazy"
                            />
                          )}
                          <Button
                            onClick={() => this._deleteContent(index)}
                            className="deletePreview"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      ) : (
                        <div className="msgPreview" key={index}>
                          <video
                            key={index}
                            src={media["previewBlob"]}
                            onLoadedMetadata={(e) =>
                              this._onLoadedMetadata(e.target, index)
                            }
                          />
                          <Button
                            onClick={() => this._deleteContent(index)}
                            className="deletePreview"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      )
                    )
                  : null}
              </div>
            </TabPane>
          </TabContent>
          {activeTab !== "4" && this._getTotalItem() ? (
            <div className="mediaLibraryPagination">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Prev"
                nextPageText="Next"
                activePage={filter.pageNumber}
                itemsCountPerPage={filter.limit}
                totalItemsCount={this._getTotalItem()}
                pageRangeDisplayed={3}
                onChange={this._handlePageChange}
              />
            </div>
          ) : null}
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between">
          {/* text to indicate the number of files selected */}
          <div className="filesSelectedTxt mr-0 mr-sm-3">
            {countLocalUploadedFile ? (
              <div className="mr-0 mr-sm-1" style={{ color: "#333" }}>
                <span>{countLocalUploadedFile}</span>{" "}
                {countLocalUploadedFile > 1 ? "files" : "file"} selected
              </div>
            ) : null}
            <div>
              {countLocalUploadedFile ? (
                <>
                  (
                  {selectedImages.length ||
                  (uploadMedias.length && this._getMediaNumber("image")) ? (
                    <span>
                      {selectedImages.length + this._getMediaNumber("image")}{" "}
                      <i className="fa fa-image" />{" "}
                    </span>
                  ) : null}
                  {selectedVideos.length ||
                  (uploadMedias.length && this._getMediaNumber("video")) ? (
                    <span>
                      {selectedVideos.length + this._getMediaNumber("video")}{" "}
                      <i className="fa fa-video-camera" />{" "}
                    </span>
                  ) : null}
                  {selectedAudios.length ||
                  (uploadMedias.length && this._getMediaNumber("audio")) ? (
                    <span>
                      {selectedAudios.length + this._getMediaNumber("audio")}{" "}
                      <i className="fa fa-microphone" />{" "}
                    </span>
                  ) : null}
                  {uploadMedias.length &&
                  this._getMediaNumber("application") ? (
                    <span>
                      {this._getMediaNumber("application")}{" "}
                      <i className="fa fa-file-pdf-o" />
                    </span>
                  ) : null}
                  )
                </>
              ) : null}
            </div>
          </div>
          <div>
            <Button
              className="modalBtnCancel"
              onClick={() => this._closeThisModal()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._submitSelectedMedia()}
              disabled={disabledSubmitBtn || !this._isAnyMediaPicked()}
            >
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state?.userData || {},
  };
};

export default connect(mapStateToProps, null)(MediaLibraryModal);
