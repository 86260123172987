import React, { Component, Fragment, Suspense } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Card, CardBody } from "reactstrap";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import {
  showToast,
  deepClone,
  convertDate,
  changeDateFormat,
  sleepTime,
  isSubscriptionPriceExist,
  extractTagUsername,
  errorHandler,
  isPermissionToAccess,
  uploadFileOnServer,
} from "../helper-methods";
import { fetchAndModifiedUserDetails } from "../redux/actions/userData";
import {
  fetchPosts,
  deletePost,
  updateAPost,
  updateList,
} from "../redux/actions/feedData";
import {
  postContent,
  getPosts,
  editPost,
  deletPost,
  sendNotificationToMentionedInfluencer,
} from "../http-calls";
import {
  UPLOAD_LIMIT,
  UPLOAD_LIMIT_TEXT,
  WELCOME_POST_IMAGE,
  WELCOME_POST_VIDEO,
} from "../config";
import { UploadQueueManager } from "../upload-queue-manager";
import { PostManager } from "../post-manager";
import CreatePost from "../components/CreatePost";
import KYCAlertCard from "../components/KYCAlertCard";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import SelectDateTimeModal from "../components/modals/SelectDateTimeModal";
import ExpirationPeriodModal from "../components/modals/ExpirationPeriodModal";
import PostCostModal from "../components/modals/PostCostModal";
import WelcomeCreatorModal from "../components/modals/WelcomeCreatorModal";
import SoundRecordUploadModal from "../components/modals/SoundRecordUploadModal";
import SetGoalAmountModal from "../components/modals/SetGoalAmountModal";
import CreateNftModal from "../components/modals/CreateNftModal";
import AddFeedPreviewModal from "../components/modals/AddFeedPreviewModal";
import CreatePollModal from "../components/modals/CreatePollModal";
import CustomTooltip from "../components/custom/CustomTooltip";
import ReKYCCard from "../components/ReKYCCard";
import ImageCropUploaderModal from "../components/modals/ImageCropUploaderModal";
// import StoryPage from "./StoryPage";
// import NewStoryModal from "../components/modals/NewStoryModal";

// code splitting
const FeedViewer = React.lazy(() => import("../components/FeedViewer"));

class FeedPage extends Component {
  state = {
    isImage: false,
    isSound: false,
    isOpenCropModal: false,
    croppedPreviewContent: null,
    isPoll: false,
    isCustomExpiryDate: false,
    expiryInDays: null,
    uploadImages: [],
    record: false,
    editMode: false,
    editedFeed: null,
    createPostRef: null,
    modals: [false, false, false, false, false, false, false],
    recordedAudio: null,
    textContent: null,
    pollForm: {
      question: "",
      options: ["", ""],
      duration: 0,
    },
    deletedMedias: [],
    addFeedPreviewModal: {
      isOpen: false,
      previewVideoUrl: {
        previewBlob: null,
        uploadUrl: null,
        uploadData: null,
        type: "video",
      },
      previewImageUrl: {
        previewBlob: null,
        uploadUrl: null,
        uploadData: null,
        type: "image",
      },
    },
    postedData: {
      uploadImages: [],
      recordedAudio: null,
      pollForm: {
        question: "",
        options: [],
        duration: 0,
      },
      startDate: null,
      expirationPeriod: 0,
      postPrice: 0,
      plusPostPrice: 0,
      premiumPostPrice: 0,
      // goal price state
      goalAmount: 0,
      minimumPaymentForGoal: 0,
      nftObject: null,
      postType: "instant",
      endDate: null,
    },
    activeIndex: 0,
    viewAsValue: "",
    isVaultFetching: false,
    feedPagination: {
      skip: 0,
      limit: 20,
    },
    feedLoading: false,
    loadMore: false,
    editFeedIndex: null,
    postInProgress: false,
    assistantSettings: null,
    uploadingMessage: "",
    tooltipOpen: [false],
    isFreeContentForAll: false,
    isPreparingPreview: false,
    newStoryModal: {
      isOpen: false,
      data: null,
    },
  };

  constructor(props) {
    super(props);
    this.createPostRef = React.createRef();
    this.feedWindow = React.createRef();
    this.expirationPeriodModalRef = React.createRef();
    this.scheduleDateModalRef = React.createRef();
    this.postCostModalRef = React.createRef();
  }

  componentDidMount() {
    const { userData } = this.props;

    // For Creator Assistant
    if (userData?.userAssistant?.isAssistant) {
      this.setState({
        assistantSettings:
          userData.userAssistant.permissionSettings && userData.userAssistant,
      });
      if (
        userData.userAssistant?.permissionSettings?.feed &&
        !userData.userAssistant.permissionSettings.feed.canViewFeed
      ) {
        showToast("Unauthorized", "error");
        // Assistant dont have access to view Feed
        this.props.history.push("/my-profile");
        return;
      }
    }

    this.props.fetchAndModifiedUserDetails();

    this._getPosts(false, true);
  }

  _initializeForm = () => {
    let {
      postedData,
      isImage,
      isPoll,
      isSound,
      uploadImages,
      editMode,
      editedFeed,
      textContent,
      postInProgress,
    } = deepClone(this.state);

    isImage = isPoll = isSound = editMode = false;
    editedFeed = null;
    uploadImages = [];

    postedData = {
      uploadImages: [],
      recordedAudio: null,
      pollForm: {
        question: "",
        options: [],
        duration: 0,
      },
      goalAmount: 0,
      minimumPaymentForGoal: 0,
      nftObject: null,
      startDate: null,
      expirationPeriod: 0,
      postPrice: 0,
      plusPostPrice: 0,
      premiumPostPrice: 0,
      postType: "instant",
      endDate: null,
    };
    textContent = null;
    uploadImages = [];
    postInProgress = false;

    this.setState({
      postedData,
      isImage,
      isPoll,
      isSound,
      editMode,
      editedFeed,
      uploadImages,
      textContent,
      postInProgress,
      isFreeContentForAll: false,
    });

    this.createPostRef.current &&
      this.createPostRef.current._initializeIsTyped();
    this.expirationPeriodModalRef.current &&
      this.expirationPeriodModalRef.current.initializeExpiryPeriod();
    this.scheduleDateModalRef.current &&
      this.scheduleDateModalRef.current.initializeScheduleDate();
    this.postCostModalRef.current &&
      this.postCostModalRef.current._initializeForm();
  };

  _scrollToTop = () => {
    if (this.feedWindow.current) {
      this.feedWindow.current.scrollTop = 0;
    }
  };

  _getPosts = async (isPaginationUpdate = true, fetchFromStart = false) => {
    this.setState({ feedLoading: true });

    const { feedPagination } = deepClone(this.state);

    try {
      if (!this.props.feedData?.feeds?.length) {
        feedPagination["skip"] = 0;
      }
      if (this.props.feedData.feeds.length && isPaginationUpdate) {
        feedPagination["skip"] = this.props.feedData.feeds.length;
      }
      if (fetchFromStart) {
        feedPagination["skip"] = 0;
      }

      await this.props.fetchPosts(feedPagination);

      this.setState({
        feedLoading: false,
        loadMore: false,
        feedPagination,
      });
    } catch (err) {
      this.setState({
        feedLoading: false,
        loadMore: false,
      });
    }
  };

  _toggleNewStoryModal = (isOpen = false, data = null) => {
    this.setState({ newStoryModal: { isOpen, data } });
  };

  _toggleModal = (index, textContent = null, isPoll = false) => {
    const { modals } = this.state;
    modals[index] = !modals[index];
    if (isPoll) {
      this.setState({ textContent });
    }
    this.setState({
      modals,
    });
  };

  _onImageUpdate = async (fileList = []) => {
    let {
      uploadImages,
      postedData,
      isImage,
      isPoll,
      isSound,
      editMode,
      editedFeed,
      deletedMedias,
    } = deepClone(this.state);

    if (
      editMode &&
      editedFeed._contents &&
      editedFeed._contents.length === 1 &&
      editedFeed._contents[0]["contentType"] === "audio"
    ) {
      deletedMedias = editedFeed._contents;
      uploadImages = [];
    }

    // let fileList =
    //   e && e.target && e.target.files && Array.from(e.target.files);

    if (fileList && fileList.length) {
      isImage = true;
      isSound = isPoll = false;

      postedData = {
        uploadImages: uploadImages.concat(fileList),
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        goalAmount: postedData["goalAmount"],
        minimumPaymentForGoal: postedData["minimumPaymentForGoal"],
        nftObject: postedData["nftObject"],
        postPrice: postedData["postPrice"],
        plusPostPrice: postedData["plusPostPrice"],
        premiumPostPrice: postedData["premiumPostPrice"],
        endDate: postedData["endDate"],
        startDate: postedData["startDate"],
      };

      this.setState({
        postedData,
        isImage,
        isSound,
        isPoll,
        uploadImages: uploadImages.concat(fileList),
        deletedMedias,
      });
    }
  };

  _saveSoundRecord = (data, isModalOpened = true) => {
    let { postedData, isImage, isPoll, isSound, deletedMedias } = deepClone(
      this.state
    );
    isImage = isPoll = false;
    isSound = true;
    let uploadSounds = [];
    if (isModalOpened) {
      this._toggleModal(0);
    } else {
      for (let i = 0; i < data.target.files.length; i++) {
        if (
          data.target.files[i] &&
          data.target.files[i].type.includes("audio")
        ) {
          if (data.target.files[i].size <= UPLOAD_LIMIT) {
            let sound = data.target.files[i];
            sound.previewBlob = URL.createObjectURL(sound);
            sound.blob = sound;
            uploadSounds.push(sound);
          } else {
            showToast(
              `Files above ${UPLOAD_LIMIT_TEXT} are currently not supported`,
              "error"
            );
          }
        }
      }
      if (!uploadSounds[0]) {
        showToast("Upload only audio file", "error");
        return;
      }
    }

    // deletedMedias = postedData["recordedAudio"] ?
    //                 deletedMedias.push({...postedData["recordedAudio"]})
    //                 : ((deletedMedias && deletedMedias.length) ? deletedMedias : postedData["uploadImages"]);
    if (postedData["recordedAudio"]) {
      deletedMedias.push({ ...postedData["recordedAudio"] });
    } else {
      if (!deletedMedias || !deletedMedias.length) {
        deletedMedias = postedData["uploadImages"];
      }
    }

    postedData = {
      uploadImages: [],
      recordedAudio: isModalOpened ? data : uploadSounds[0],
      pollForm: {
        question: "",
        options: [],
        duration: 0,
      },
      goalAmount: postedData["goalAmount"],
      minimumPaymentForGoal: postedData["minimumPaymentForGoal"],
      nftObject: postedData["nftObject"],
      postPrice: postedData["postPrice"],
      plusPostPrice: postedData["plusPostPrice"],
      premiumPostPrice: postedData["premiumPostPrice"],
      endDate: postedData["endDate"],
      startDate: postedData["startDate"],
      postType: "instant",
    };

    let isVideoFileExist = postedData?.uploadImages.some(
      (media) =>
        (media &&
          media.type &&
          media.type.split("/") &&
          media.type.split("/")[0] &&
          media.type.split("/")[0] === "video") ||
        (media.contentType && media.contentType === "video")
    );

    if (!isVideoFileExist) this._resetPreview();

    this.setState(
      { postedData, isImage, isSound, isPoll, deletedMedias },
      () => {}
    );
  };

  _cancelSoundRecording = () => {
    this._toggleModal(0);
    const { postedData } = deepClone(this.state);

    postedData["recordedAudio"] = null;
    this.setState({ postedData });
  };

  _savePollForm = (data) => {
    let { postedData, isImage, isPoll, isSound } = deepClone(this.state);

    postedData = {
      uploadImages: [],
      recordedAudio: null,
      pollForm: {
        question: "",
        options: [],
        duration: 0,
      },
      goalAmount: 0,
      minimumPaymentForGoal: 0,
      nftObject: null,
      postPrice: 0,
      plusPostPrice: 0,
      premiumPostPrice: 0,
    };
    // const optionsSet = data["options"]["value"];

    postedData["pollForm"]["question"] = data["question"]["value"].trim();
    postedData["pollForm"]["duration"] = data["duration"]["value"];
    postedData["pollForm"]["options"] = data["options"]["value"].filter(
      (option) => {
        return option.text && option.text.trim().length;
      }
    );

    postedData["pollForm"]["options"].isImage = isSound = false;

    isPoll = true;

    this._toggleModal(2);

    this.setState({ postedData, isImage, isSound, isPoll }, () => {
      this._postData();
    });
    // const { pollForm, postedData } = deepClone(this.state);
    // postedData['pollForm'] = pollForm;
    // this.setState({ postedData });
  };

  _resetFreeContentForAll = () => {
    this.setState({ isFreeContentForAll: false });
  };

  _savePostPrice = ({ basic, plus, premium, isFreeContentForAll }) => {
    const { postedData } = deepClone(this.state);
    const { userData } = deepClone(this.props);

    postedData["goalAmount"] = 0;
    postedData["minimumPaymentForGoal"] = 0;

    postedData["nftObject"] = null;

    postedData["postPrice"] = isFreeContentForAll ? 0 : basic;

    if (userData?.user?.multiTierSubscription) {
      if (isSubscriptionPriceExist("plus")) {
        postedData["plusPostPrice"] = isFreeContentForAll ? 0 : plus || 0;
      }
      if (isSubscriptionPriceExist("premium")) {
        postedData["premiumPostPrice"] = isFreeContentForAll ? 0 : premium || 0;
      }
    }

    this.setState({ postedData, isFreeContentForAll }, () =>
      this._toggleModal(3)
    );
  };

  //goal modal save
  _saveGoalPrice = (goalAmount = 0, minimumPaymentForGoal = 0) => {
    const { postedData } = deepClone(this.state);

    postedData["postPrice"] = 0;
    postedData["plusPostPrice"] = 0;
    postedData["premiumPostPrice"] = 0;

    postedData["nftObject"] = null;

    postedData["goalAmount"] = goalAmount;
    postedData["minimumPaymentForGoal"] = minimumPaymentForGoal;

    this.setState({ postedData });
  };

  // nft modal save
  _saveNftObject = (nftObject = null) => {
    const { postedData } = deepClone(this.state);

    postedData["postPrice"] = 0;
    postedData["plusPostPrice"] = 0;
    postedData["premiumPostPrice"] = 0;

    postedData["goalAmount"] = 0;
    postedData["minimumPaymentForGoal"] = 0;

    postedData["nftObject"] = nftObject ? nftObject : null;

    this.setState({ postedData });
  };

  _saveScheduledPostDate = (data) => {
    const { postedData, expiryInDays } = deepClone(this.state);
    postedData["startDate"] = convertDate(data);
    if (expiryInDays !== null) {
      postedData["endDate"] = moment(changeDateFormat(data))
        .add(Number(expiryInDays), "day")
        .endOf("day")
        .format("YYYY-MM-DD, h:mm a");
    } else if (
      postedData["endDate"] !== null &&
      moment(postedData["endDate"]).isBefore(moment(postedData["startDate"]))
    ) {
      postedData["endDate"] = null;
    }
    this.setState({ postedData }, () => this._toggleModal(4));
  };

  _cancelScheduledPost = () => {
    this._toggleModal(4);
  };

  _saveExpirationPeriod = (
    isCustomExpiryDate = null,
    data = null,
    expiryInDays = null
  ) => {
    this.setState({ isCustomExpiryDate, expiryInDays });
    if (data) {
      const { postedData } = deepClone(this.state);

      if (expiryInDays !== null && postedData["startDate"] !== null) {
        postedData["endDate"] = moment(
          changeDateFormat(postedData["startDate"])
        )
          .add(Number(expiryInDays), "day")
          .endOf("day")
          .format("YYYY-MM-DD, h:mm a");
      } else {
        postedData["endDate"] = convertDate(data);
      }
      if (
        postedData["startDate"] !== null &&
        moment(postedData["startDate"]).isAfter(moment(postedData["endDate"]))
      ) {
        delete postedData["startDate"];
      }
      this.setState({ postedData });
    }
    this._toggleModal(1);
  };

  _cancelExpirationPeriod = () => {
    this._toggleModal(1);
  };

  _postData = async (data = null) => {
    let {
      // uploadingMessage,
      deletedMedias,
      addFeedPreviewModal,
      postedData,
      isImage,
      isSound,
      isPoll,
      editMode,
      textContent,
      isFreeContentForAll,
    } = deepClone(this.state);

    this.setState({
      uploadingMessage: "Preparing your contents",
      postInProgress: true,
    });

    let apiData = {
      category: isPoll ? "poll" : "general",
      text: isPoll
        ? postedData["pollForm"]["question"].replace(/\n/g, "<br/>")
        : data?.trim()?.length
        ? data?.trim()
        : null,
      description: isPoll
        ? textContent && textContent.length
          ? textContent.replace(/\n/g, "<br/>")
          : null
        : null,
      options: isPoll ? postedData["pollForm"]["options"] : [],
      contents:
        postedData["uploadImages"].length ||
        (postedData.hasOwnProperty("recordedAudio") && postedData.recordedAudio)
          ? []
          : null,
      isPublic: isPoll
        ? true
        : postedData.hasOwnProperty("postPrice") ||
          postedData.hasOwnProperty("plusPostPrice") ||
          postedData.hasOwnProperty("premiumPostPrice")
        ? (Number(postedData.postPrice) === 0 || !postedData.postPrice) &&
          (Number(postedData.plusPostPrice) === 0 ||
            !postedData.plusPostPrice) &&
          (Number(postedData.premiumPostPrice) === 0 ||
            !postedData.premiumPostPrice)
          ? true
          : false
        : true,
      price: isPoll
        ? 0
        : postedData.postPrice
        ? Number(postedData.postPrice)
        : 0,
      plusPrice: isPoll
        ? 0
        : postedData.plusPostPrice
        ? Number(postedData.plusPostPrice)
        : 0,
      premiumPrice: isPoll
        ? 0
        : postedData.premiumPostPrice
        ? Number(postedData.premiumPostPrice)
        : 0,
      postType: "instant",
      startDate: isPoll
        ? null
        : postedData["startDate"]
        ? moment(postedData["startDate"]).isBefore(moment())
          ? null
          : new Date(
              changeDateFormat(convertDate(postedData["startDate"]))
            ).toISOString()
        : null,
      endDate: isPoll
        ? postedData.pollForm.duration !== 0
          ? moment(
              moment()
                .add(Number(postedData.pollForm.duration), "day")
                .endOf("day")
                .format("YYYY-MM-DD")
            ).toISOString()
          : null
        : postedData["endDate"]
        ? new Date(
            changeDateFormat(convertDate(postedData["endDate"]))
          ).toISOString()
        : null,
    };

    // console.log({ data, apiData });
    // return;

    if (apiData.isPublic && isFreeContentForAll) {
      apiData.isFree = true;
    }

    //goal api data
    if (postedData.goalAmount && postedData.minimumPaymentForGoal) {
      apiData["category"] = "goal";

      apiData["goalAmount"] = postedData.goalAmount;
      apiData["minimumPaymentForGoal"] = postedData.minimumPaymentForGoal;

      apiData["isPublic"] = true;
      apiData["price"] = 0;
      apiData["plusPrice"] = 0;
      apiData["premiumPrice"] = 0;
      apiData["isPinned"] = true;
      apiData["endDate"] = null;
    }

    // find out 'postType'
    if (apiData["startDate"] && apiData["endDate"]) {
      apiData["postType"] = "scheduled-temporary";
    } else if (apiData["startDate"] === null && apiData["endDate"] === null) {
      apiData["postType"] = "instant";
    } else if (apiData["startDate"]) {
      apiData["postType"] = "scheduled";
    } else if (apiData["endDate"]) {
      apiData["postType"] = "temporary";
    }

    if (apiData["postType"] === "scheduled") {
      delete apiData["endDate"];
      // editMode ? (apiData["endDate"] = null) : delete apiData["endDate"];
    } else if (apiData["postType"] === "temporary") {
      delete apiData["startDate"];
      // editMode ? (apiData["startDate"] = null) : delete apiData["startDate"];
    } else if (apiData["postType"] !== "scheduled-temporary") {
      delete apiData["startDate"];
      delete apiData["endDate"];
    }

    // for posting new feed
    if (!editMode) {
      if (addFeedPreviewModal?.previewImageUrl?.uploadData) {
        isImage = true;

        const previewImageObj = {
          forKeyName: "previewImageUrl",
          type: "image",
          previewBlob: addFeedPreviewModal?.previewImageUrl?.previewBlob,
          uploadData: addFeedPreviewModal?.previewImageUrl?.uploadData,
        };

        postedData["uploadImages"].push(previewImageObj);
      }

      if (addFeedPreviewModal?.previewVideoUrl?.uploadData) {
        // apiData["previewVideoOption"] = "upload";

        isImage = true;

        const previewVideoObj = {
          forKeyName: "previewVideoUrl",
          type: "video",
          previewBlob: addFeedPreviewModal?.previewVideoUrl?.previewBlob,
          uploadData: addFeedPreviewModal?.previewVideoUrl?.uploadData,
        };

        postedData["uploadImages"].push(previewVideoObj);
      }

      if (!isPoll) {
        delete apiData["options"];

        if (isImage) {
          const libraryFiles = [];
          const filesToUpload = [];

          postedData["uploadImages"].forEach((each) => {
            if (each.url) {
              libraryFiles.push({
                id: each?._id, //for avoiding repeatition of content via BE
                contentType: each.contentType,
                url: each.url,
                duration: each?.duration ? each?.duration : null,
              });
            } else {
              filesToUpload.push(each);
            }
          });

          if (filesToUpload?.length) {
            const mediaRes = await uploadFileOnServer(filesToUpload);

            mediaRes.forEach((res) => {
              if (res.forKeyName === "previewImageUrl") {
                apiData["preview"] = res.url;
              } else if (res.forKeyName === "previewVideoUrl") {
                apiData["previewVideoUrl"] = res.url;
              } else {
                let contentObj = {
                  contentType: res.contentType,
                  url: res.url,
                  duration: res?.duration ? res?.duration : null,
                };
                apiData["contents"].push(contentObj);
              }
            });
          }

          if (libraryFiles?.length) {
            libraryFiles?.forEach((libraryFile) => {
              apiData["contents"].push(libraryFile);
            });
          }

          this._callPostContentAPI(apiData);
        } else if (isSound && postedData.recordedAudio) {
          let postID = PostManager.addMediaFilesCount(1);
          PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
            if (id.postID === postID) {
              await sleepTime(500);
              this._callPostContentAPI(apiData);
              PostManager.deletePostID(postID);
            } else {
              return;
            }
          });
          // for sound
          const mediaData = {
            blobObject: postedData.recordedAudio,
          };
          const uploadId = UploadQueueManager.addMediaToQueue(
            mediaData,
            "audio"
          );

          // Listen for upload complete
          UploadQueueManager.onUploadComplete(
            uploadId,
            async (mediaResponse) => {
              // Upload complete
              // Get content id from backend
              apiData["contents"].push({
                contentType: mediaResponse.data.mediaType,
                url: mediaResponse.fileUrl,
              });
              PostManager.onSingleMediaFileUploadCompleted(postID);
            }
          );
        } else {
          apiData["price"] = 0;
          apiData["plusPrice"] = 0;
          apiData["premiumPrice"] = 0;
          apiData["isPublic"] = true;
          // when only text content presents
          delete apiData["contents"];
          await sleepTime(500);

          this._callPostContentAPI(apiData);
        }
      } else {
        // for posting poll
        delete apiData["contents"];
        await sleepTime(500);
        this._callPostContentAPI(apiData);
        if (this.createPostRef?.current?._clearContent)
          this.createPostRef.current._clearContent();
        this._initializeForm();
      }
    } else {
      // for editing existing feed
      if (addFeedPreviewModal?.previewImageUrl?.uploadData) {
        isImage = true;

        const previewImageObj = {
          keyName: "previewImageUrl",
          type: "image",
          previewBlob: addFeedPreviewModal?.previewImageUrl?.previewBlob,
          uploadData: addFeedPreviewModal?.previewImageUrl?.uploadData,
        };

        postedData["uploadImages"].push(previewImageObj);
      }
      if (addFeedPreviewModal?.previewVideoUrl?.uploadData) {
        // apiData["previewVideoOption"] = "upload";

        isImage = true;

        const previewImageObj = {
          keyName: "previewVideoUrl",
          type: "video",
          previewBlob: addFeedPreviewModal?.previewVideoUrl?.previewBlob,
          uploadData: addFeedPreviewModal?.previewVideoUrl?.uploadData,
        };

        postedData["uploadImages"].push(previewImageObj);
      }

      if (!isPoll) {
        if (isSound && postedData.recordedAudio) {
          let postID = PostManager.addMediaFilesCount(1);
          PostManager.onAllMediaFilesUploadCompleted(postID, (id) => {
            if (id.postID === postID) {
              this._makeEditAPIData(apiData, this.state.deletedMedias);
              PostManager.deletePostID(postID);
            } else {
              return;
            }
          });

          delete apiData["options"];

          // let { uploadImages } = deepClone(this.state);

          // deletedMedias = uploadImages;

          deletedMedias =
            deletedMedias && deletedMedias.length
              ? deletedMedias
              : postedData["uploadImages"];

          this.setState({ deletedMedias }, () => {
            const mediaData = {
              blobObject: postedData.recordedAudio,
            };

            apiData["contents"] =
              apiData["contents"] === null ? [] : apiData["contents"];

            const uploadId = UploadQueueManager.addMediaToQueue(
              mediaData,
              "audio"
            );

            // Listen for upload complete
            UploadQueueManager.onUploadComplete(
              uploadId,
              async (mediaResponse) => {
                // Upload complete
                // Get content id from backend
                apiData["contents"].push({
                  contentType: mediaResponse.data.mediaType,
                  url: mediaResponse.fileUrl,
                  isNew: true,
                });

                PostManager.onSingleMediaFileUploadCompleted(postID);
              }
            );
          });
        } else if (isImage) {
          delete apiData["options"];
          let { uploadImages, deletedMedias } = deepClone(this.state);
          deletedMedias = uploadImages;
          this.setState(deletedMedias);
          let newAddedContents = postedData["uploadImages"].filter(
            (content) => {
              return content.type;
            }
          );

          let postID = PostManager.addMediaFilesCount(newAddedContents.length);
          PostManager.onAllMediaFilesUploadCompleted(postID, (id) => {
            if (id.postID === postID) {
              this._makeEditAPIData(apiData, this.state.deletedMedias);

              PostManager.deletePostID(postID);
            } else {
              return;
            }
          });

          apiData["contents"] = postedData["uploadImages"].filter((content) => {
            return content.contentType;
          });
          if (newAddedContents && newAddedContents.length) {
            for (let i = 0; i < newAddedContents.length; i++) {
              new Promise((resolve, reject) => {
                let mediaData = {};
                if (newAddedContents[i]["type"].split("/")[0] === "video") {
                  mediaData = {
                    blobObject: newAddedContents[i]["uploadData"],
                    blobURL: newAddedContents[i]["uploadData"]["previewBlob"],
                  };
                } else if (
                  newAddedContents[i]["type"].split("/")[0] === "image"
                ) {
                  mediaData = {
                    file: newAddedContents[i]["uploadData"],
                    blobObject:
                      newAddedContents[i]["uploadData"]["previewBlob"],
                  };
                }

                // const mediaData = {
                //   file: newAddedContents[i]["uploadData"],
                //   blobObject: newAddedContents[i]["uploadData"]["previewBlob"]
                // };

                const uploadId = UploadQueueManager.addMediaToQueue(
                  mediaData,
                  newAddedContents[i]["type"].split("/")[0]
                );

                // Listen for upload complete
                UploadQueueManager.onUploadComplete(
                  uploadId,
                  async (mediaResponse) => {
                    PostManager.onSingleMediaFileUploadCompleted(postID);

                    if (newAddedContents[i].keyName === "previewImageUrl") {
                      apiData["preview"] = mediaResponse.fileUrl;
                    } else if (
                      newAddedContents[i].keyName === "previewVideoUrl"
                    ) {
                      apiData["previewVideoUrl"] = mediaResponse.fileUrl;
                    } else {
                      // Upload complete
                      // Get content id from backend
                      apiData["contents"].push({
                        contentType: mediaResponse.data.mediaType,
                        url: mediaResponse.fileUrl,
                        isNew: true,
                      });
                    }
                  }
                );
              });
            }
          } else {
            const { deletedMedias } = deepClone(this.state);
            apiData["contents"] = [];
            // apiData["price"] = 0;
            this._makeEditAPIData(apiData, deletedMedias);
          }
        } else {
          const { deletedMedias } = deepClone(this.state);
          apiData["contents"] = [];
          // apiData["price"] = 0;
          this._makeEditAPIData(apiData, deletedMedias);
        }
      }
    }
  };

  _makeEditAPIData = (newMedias, deletedMedias) => {
    let data = [];
    // data = data.concat(newMedias);
    for (let i = 0; i < deletedMedias.length; i++) {
      if (deletedMedias[i].hasOwnProperty("_id")) {
        data.push({
          id: deletedMedias[i]._id,
          doRemove: true,
        });
      }
    }
    newMedias["contents"] = newMedias["contents"].concat(data);

    this._callPostContentAPI(newMedias);
  };

  _getIndividualPost = async (index, limit = 1) => {
    try {
      let elem = document.getElementById(this.state.editFeedIndex);

      if (elem) {
        elem.scrollIntoView(true);
      }

      const res = await getPosts({ skip: index, limit });

      await this.props.updateList({
        index: this.state.editFeedIndex,
        post: res.posts[0],
      });
    } catch (error) {
      this._getPosts(false, true);
      errorHandler(error);
    }
  };

  _callPostContentAPI = (data) => {
    const { editedFeed, editMode, editFeedIndex } = deepClone(this.state);

    if (editMode) {
      // data["id"] = editedFeed.id;

      let sendNewlyTagUsername = [];

      if (data?.text) {
        const oldTagUsernameArray = editedFeed?._mentionedInfluencer?.length
          ? editedFeed._mentionedInfluencer
          : [];

        const newTagUsernameArray = extractTagUsername(data.text);

        data["_mentionedInfluencer"] = newTagUsernameArray?.length
          ? newTagUsernameArray
          : [];

        if (newTagUsernameArray?.length) {
          sendNewlyTagUsername = newTagUsernameArray.filter(
            (newEach) => !oldTagUsernameArray.includes(newEach)
          );
        }
      }

      editPost(data, editedFeed.id)
        .then((res) => {
          if (sendNewlyTagUsername?.length && res?.post?._id) {
            const payload = {
              username: sendNewlyTagUsername,
              postId: res.post._id,
            };
            sendNotificationToMentionedInfluencer(payload);
          }

          this._resetPreview();

          if (this.createPostRef?.current?._clearContent)
            this.createPostRef.current._clearContent();

          this._initializeForm();
          // this.createPostRef.current._initializeIsTyped();
          showToast("Updated sucessfully", "success");
          this._initializeForm();
          this._getIndividualPost(editFeedIndex);
        })
        .catch((error) => {
          console.log("error>>", error);
          this.setState({ postInProgress: false });
          errorHandler(error);
        });
    } else {
      let tagUsernameArray = [];

      if (data?.text) {
        tagUsernameArray = extractTagUsername(data.text);
        data["_mentionedInfluencer"] = tagUsernameArray?.length
          ? tagUsernameArray
          : [];
      }

      postContent(data)
        .then((res) => {
          if (tagUsernameArray?.length && res?.post?._id) {
            const payload = {
              username: tagUsernameArray,
              postId: res.post._id,
            };
            sendNotificationToMentionedInfluencer(payload);
          }

          this._resetPreview();
          // showToast("Posted successfully", "success");
          showToast("Posted Successfully", "success");

          if (this.createPostRef?.current?._clearContent)
            this.createPostRef.current._clearContent();

          // this.createPostRef.current._initializeIsTyped();
          this._initializeForm();
          this._getPosts(false, true);
          this._scrollToTop();
          this._toggleNewStoryModal(true, res.post);
        })
        .catch((error) => {
          this.setState({
            postInProgress: false,
          });
          errorHandler(error);
        });
    }
  };

  _deleteCost = (fieldName) => {
    const { postedData } = deepClone(this.state);
    postedData[fieldName] = 0;

    if (
      !postedData["postCost"] &&
      !postedData["plusPostPrice"] &&
      !postedData["premiumPostPrice"]
    )
      this._resetPreview("previewVideoUrl");

    this.setState({ postedData });
  };

  _deleteContent = (index) => {
    let { postedData, uploadImages, deletedMedias, isImage, isSound } =
      deepClone(this.state);

    // postedData["uploadImages"].splice(index, 1);
    if (postedData["recordedAudio"]) {
      deletedMedias.push(postedData["recordedAudio"]);
      postedData["recordedAudio"] = null;
      isSound = false;
      // this._deleteCost();
    } else {
      deletedMedias.push(postedData["uploadImages"][index]);
      postedData["uploadImages"].splice(index, 1);
    }
    uploadImages = postedData["uploadImages"];
    if (!uploadImages || !uploadImages.length) {
      // this._deleteCost();
      isImage = false;
    }

    let isVideoFileExist = uploadImages.some(
      (media) =>
        (media &&
          media.type &&
          media.type.split("/") &&
          media.type.split("/")[0] &&
          media.type.split("/")[0] === "video") ||
        (media.contentType && media.contentType === "video")
    );

    if (!isVideoFileExist) this._resetPreview();

    this.setState({
      postedData,
      uploadImages,
      deletedMedias,
      isImage,
      isSound,
    });
  };

  _pinToProfile = async (id, isPinned, index) => {
    try {
      this.props.updateAPost({
        index,
        attr: "isPinned",
        value: isPinned,
      });

      await editPost({ isPinned }, id);

      this._getPosts(false, true);

      this._scrollToTop();
    } catch (error) {
      errorHandler(error);

      this.props.updateAPost({
        index,
        attr: "isPinned",
        value: !isPinned,
      });
    }
  };

  _editFeed = (feed, index = null) => {
    this.createPostRef.current._initializeIsTyped();
    // this._initializeForm();
    this._scrollToTop();
    let {
      editedFeed,
      editMode,
      postedData,
      uploadImages,
      // isImage,
      isPoll,
      // isSound,
      // deletedMedias,
      addFeedPreviewModal,
    } = deepClone(this.state);
    this.setState({ deletedMedias: [] });
    isPoll = feed.category === "poll" ? true : false;

    addFeedPreviewModal["previewImageUrl"] = {
      previewBlob: null,
      uploadUrl: feed?.preview || null,
      uploadData: null,
      type: "image",
    };
    addFeedPreviewModal["previewVideoUrl"] = {
      previewBlob: null,
      uploadUrl: feed?.previewVideoUrl || null,
      uploadData: null,
      type: "video",
    };

    editedFeed = feed;
    editMode = true;
    postedData = feed;

    if (feed.category === "goal") {
      uploadImages = feed._contents;
      postedData["uploadImages"] = feed._contents;
      postedData["postType"] = feed.postType;
      postedData["text"] = feed.text?.trim();
      postedData["goalAmount"] = feed.goalAmount;
      postedData["minimumPaymentForGoal"] = feed.minimumPaymentForGoal;
    } else if (feed.category === "general") {
      uploadImages = feed._contents;
      postedData["uploadImages"] = feed._contents;
      postedData["postPrice"] = feed.price;
      postedData["plusPostPrice"] = feed.plusPrice;
      postedData["premiumPostPrice"] = feed.premiumPrice;
      postedData["postType"] = feed.postType;
      postedData["text"] = feed.text?.trim();
    }

    this.setState({
      isPoll,
      uploadImages,
      postedData,
      editedFeed,
      editMode,
      editFeedIndex: index,
      addFeedPreviewModal,
      isFreeContentForAll: feed.isFree || false,
    });
  };

  _deletePost = async (id, index) => {
    try {
      await deletPost(id);

      this.props.deletePost(index);

      showToast("Post has been deleted", "success");
    } catch (error) {
      errorHandler(error);
    }
  };

  _removeExpireDate = () => {
    const { postedData } = deepClone(this.state);
    postedData["endDate"] = null;
    this.expirationPeriodModalRef.current.initializeExpiryPeriod();
    this.setState({ postedData, expiryInDays: null });
  };

  _removeScheduleDate = () => {
    const { postedData } = deepClone(this.state);
    postedData["startDate"] = null;
    this.scheduleDateModalRef.current.initializeScheduleDate();
    this.setState({ postedData });
  };

  //  disable work is pending (Don't remove it)
  // _changeViewAs = (viewAsValue) => {
  //   this.setState({ viewAsValue }, () => {
  //     switch (viewAsValue) {
  //       case "subscriber": {
  //         this.props.history.push("subscriber-view");
  //         break;
  //       }
  //       case "nonSubscriber": {
  //         this.props.history.push("non-subscriber-view");
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   });
  // };

  _loadMoreFeeds = () => {
    if (!this.state.loadMore && !this.state.feedLoading) {
      this.setState({ loadMore: true }, () => this._getPosts());
    }
  };

  _onCommentPost = (index, isAdded) => {
    try {
      let commentsCount = 0;

      const { feedData } = this.props;

      if (isAdded) {
        if (feedData?.feeds?.[index]?.hasOwnProperty("comments")) {
          commentsCount = feedData.feeds[index]["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (+feedData?.feeds?.[index]?.comments) {
          commentsCount = feedData.feeds[index]["comments"] - 1;
        }
      }

      this.props.updateAPost({ index, attr: "comments", value: commentsCount });
    } catch (error) {
      console.log({ error });
    }
  };

  _updateCommentsCount = ({ index, value }) => {
    this.props.updateAPost({
      index: index,
      attr: "comments",
      value,
    });
  };

  _resetFeedPostData = () => {
    this.setState({
      isImage: false,
      isSound: false,
      isOpenCropModal: false,
      croppedPreviewContent: null,
      isPoll: false,
      isCustomExpiryDate: false,
      expiryInDays: null,
      uploadImages: [],
      record: false,
      editMode: false,
      editedFeed: null,
      createPostRef: null,
      modals: [false, false, false, false, false, false, false],
      recordedAudio: null,
      textContent: null,
      pollForm: {
        question: "",
        options: ["", ""],
        duration: 0,
      },
      deletedMedias: [],
      addFeedPreviewModal: {
        isOpen: false,
        previewVideoUrl: {
          previewBlob: null,
          uploadUrl: null,
          uploadData: null,
          type: "video",
        },
        previewImageUrl: {
          previewBlob: null,
          uploadUrl: null,
          uploadData: null,
          type: "image",
        },
      },

      postedData: {
        uploadImages: [],
        recordedAudio: null,
        pollForm: {
          question: "",
          options: [],
          duration: 0,
        },
        startDate: null,
        expirationPeriod: 0,
        postPrice: 0,
        plusPostPrice: 0,
        premiumPostPrice: 0,
        postType: "instant",
        endDate: null,
        goalAmount: 0,
        minimumPaymentForGoal: 0,
        nftObject: null,
      },
      activeIndex: 0,
      viewAsValue: "",
      isVaultFetching: false,
      feedPagination: {
        skip: 0,
        limit: 20,
      },
      loadMore: false,
      editFeedIndex: null,
      postInProgress: false,
      assistantSettings: null,
      uploadingMessage: "",
      isFreeContentForAll: false,
    });
  };

  _toggleTooltip = (index) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[index] = !tooltipOpen[index];
    this.setState({
      tooltipOpen,
    });
  };

  _toggleCropModal = (isOpen = false) => {
    this.setState({ isOpenCropModal: isOpen });
  };

  _toggleAddFeedPreviewModal = (isOpen = false) => {
    const { addFeedPreviewModal } = this.state;
    addFeedPreviewModal["isOpen"] = isOpen;
    this.setState({ addFeedPreviewModal });
  };

  _onChangePreview = (e) => {
    try {
      if (!e?.target?.files?.length) return;

      const file = e.target?.files?.[0];
      const fileType = file.type?.split("/")?.[0];

      if (!fileType?.includes("image") && !fileType?.includes("video")) {
        showToast("Only Image and Video file are supported.");
        return;
      }

      const { addFeedPreviewModal } = this.state;

      const key = fileType?.includes("image")
        ? "previewImageUrl"
        : "previewVideoUrl";

      addFeedPreviewModal[key]["uploadData"] = file;
      addFeedPreviewModal[key]["previewBlob"] = URL.createObjectURL(file);
      addFeedPreviewModal[key]["type"] = fileType;
      addFeedPreviewModal[key]["uploadUrl"] = null;

      if (key === "previewImageUrl") {
        addFeedPreviewModal["previewVideoUrl"]["uploadData"] = null;
        addFeedPreviewModal["previewVideoUrl"]["previewBlob"] = null;
        addFeedPreviewModal["previewVideoUrl"]["type"] = null;
        addFeedPreviewModal["previewVideoUrl"]["uploadUrl"] = null;
      } else {
        addFeedPreviewModal["previewImageUrl"]["uploadData"] = null;
        addFeedPreviewModal["previewImageUrl"]["previewBlob"] = null;
        addFeedPreviewModal["previewImageUrl"]["type"] = null;
        addFeedPreviewModal["previewImageUrl"]["uploadUrl"] = null;
      }

      this.setState({ addFeedPreviewModal });
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  // recieve cropped photo and update the preview
  _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
    const { addFeedPreviewModal } = this.state;

    addFeedPreviewModal["previewImageUrl"]["uploadData"] = croppedImageBlob;
    addFeedPreviewModal["previewImageUrl"]["previewBlob"] = croppedImageUrl;
    addFeedPreviewModal["previewImageUrl"]["type"] = "image";
    addFeedPreviewModal["previewImageUrl"]["uploadUrl"] = null;

    this.setState({ addFeedPreviewModal, isOpenCropModal: false });
  };

  _resetPreview = () => {
    try {
      const { addFeedPreviewModal } = this.state;

      addFeedPreviewModal["previewVideoUrl"] = {
        previewBlob: null,
        uploadUrl: null,
        uploadData: null,
        type: "video",
      };
      addFeedPreviewModal["previewImageUrl"] = {
        previewBlob: null,
        uploadUrl: null,
        uploadData: null,
        type: "image",
      };

      this.setState({ addFeedPreviewModal });
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  render() {
    const {
      postedData,
      isImage,
      isSound,
      isOpenCropModal,
      croppedPreviewContent,
      isPoll,
      editedFeed,
      editMode,
      feedPagination,
      loadMore,
      postInProgress,
      addFeedPreviewModal,
      isFreeContentForAll,
      feedLoading,
      isPreparingPreview,
    } = deepClone(this.state);

    const { feedData, userData } = deepClone(this.props);

    // check if any preview is already added while creating a new video post
    const isPreviewAdded =
      addFeedPreviewModal?.previewImageUrl?.previewBlob !== null ||
      addFeedPreviewModal?.previewVideoUrl?.previewBlob !== null;

    return (
      <div className="customPgHeight animated fadeIn" ref={this.feedWindow}>
        <InfiniteScroll
          pageStart={feedPagination.skip}
          loadMore={this._loadMoreFeeds}
          hasMore={
            feedData?.feeds?.length < feedData?.feedsCount
              ? true || false
              : false
          }
          loader={
            loadMore && feedLoading ? (
              <Container className="noPadding" key={`feepage_InfiniteScroll`}>
                <Row
                  className="justify-content-center noMargin"
                  key={"feedPageLoader"}
                >
                  <Col
                    sm={12}
                    lg={10}
                    xl={8}
                    className="noPadding bg-white mb-3"
                    style={{ borderRadius: 8 }}
                  >
                    <SkeletonLoading type={"post"} count={2} />
                  </Col>
                </Row>
              </Container>
            ) : null
          }
          useWindow={false}
          threshold={1000}
        >
          <Container className="noPadding">
            <Row className="justify-content-center noMargin">
              <Col
                sm={12}
                md={11}
                lg={8}
                xl={7}
                className="noPadding bg-white mb-3"
                style={{
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                {/* stripe kyc alert card */}
                <KYCAlertCard />

                <ReKYCCard />

                {/* <StoryPage /> */}

                {isPermissionToAccess("feed", "canCreatePostOrPoll", true) ? (
                  <>
                    <div className="createPostTxtWrap">
                      <div className="d-flex">
                        <h4>{editMode ? "Edit Post" : "Create New Post"}</h4>

                        <sup
                          id="feedpage_TooltipFirstName"
                          className="infoIcon"
                          style={{ top: 0 }}
                        >
                          <i className="fa fa-info-circle" />
                        </sup>
                        <CustomTooltip
                          text={`Uploaded content can be set to expire, scheduled to post, or have a price associated with it making it a pay-per-view post.`}
                          target="feedpage_TooltipFirstName"
                        />
                      </div>

                      {/* disable work is pending  (Don't remove it) */}
                      {/* <Input
                        type="select"
                        name="view"
                        id="view"
                        value={viewAsValue}
                        onChange={(e) => this._changeViewAs(e.target.value)}
                      >
                        <option value="">View As</option>
                        <option value="subscriber">Subscriber</option>
                        <option value="nonSubscriber">Non-Subscriber</option>
                      </Input> */}
                    </div>

                    <CreatePost
                      ref={this.createPostRef}
                      isPostButtonEnabled={isImage || isSound || isPoll}
                      isShowPreviewBtn={true}
                      isImage={isImage}
                      isPoll={isPoll}
                      isPreviewAdded={isPreviewAdded}
                      isSound={isSound}
                      onClick={(index, textContent, clickedPollModal) =>
                        this._toggleModal(index, textContent, clickedPollModal)
                      }
                      selectedMedias={postedData["uploadImages"]}
                      resetFreeContentForAll={() =>
                        this._resetFreeContentForAll()
                      }
                      deleteCost={(fieldName) => this._deleteCost(fieldName)}
                      selectedAudio={postedData["recordedAudio"]}
                      uploadFile={(e) => this._onImageUpdate(e)}
                      addFeedPreviewModal={addFeedPreviewModal}
                      toggleAddFeedPreviewModal={
                        this._toggleAddFeedPreviewModal
                      }
                      resetPreview={this._resetPreview}
                      onPost={(data) => this._postData(data)}
                      isFreeContentForAll={isFreeContentForAll}
                      postCost={postedData["postPrice"]}
                      plusPostPrice={postedData["plusPostPrice"]}
                      premiumPostPrice={postedData["premiumPostPrice"]}
                      goalAmount={postedData["goalAmount"]}
                      nftObject={postedData["nftObject"]}
                      saveGoalPrice={() => this._saveGoalPrice()}
                      saveNftObject={() => this._saveNftObject()}
                      deleteContent={(index) => this._deleteContent(index)}
                      editMode={editMode}
                      editedFeed={editedFeed}
                      userData={this.props.userData}
                      endDate={
                        isPoll
                          ? postedData.pollForm.duration !== 0
                            ? moment()
                                .add(
                                  Number(postedData.pollForm.duration),
                                  "day"
                                )
                                .endOf("day")
                                .format("YYYY-MM-DD, h:mm a")
                            : null
                          : postedData["endDate"]
                          ? postedData["endDate"]
                          : null
                      }
                      startDate={
                        isPoll
                          ? null
                          : postedData["startDate"]
                          ? convertDate(postedData["startDate"])
                          : null
                      }
                      removeExpireDate={this._removeExpireDate}
                      removeScheduleDate={this._removeScheduleDate}
                      onSoundSave={(data, isModalOpened) =>
                        this._saveSoundRecord(data, isModalOpened)
                      }
                      onSoundCancel={() => this._cancelSoundRecording()}
                      isPollDisplay={true}
                      isScheduleDateDisplay={true}
                      isExpiryDateDisplay={true}
                      isCostDisplay={true}
                      isShowCancelBtn={true}
                      isShowGoalBtn={true}
                      isShowNFTBtn={false}
                      cancelEditFeed={() => this._resetFeedPostData()}
                      postInProgress={postInProgress}
                      isPreparingPreview={isPreparingPreview}
                      isUploadLimit={true}
                      imageLimit={10}
                      videoLimit={10}
                      audioLimit={5}
                      shouldUploadInModal={false}
                    />
                  </>
                ) : null}
              </Col>

              <Col
                sm={12}
                md={11}
                lg={8}
                xl={7}
                className="noPadding bg-white mb-3"
                style={{ borderRadius: 8 }}
              >
                {/* multiple posts/feeds */}
                {feedData?.feeds?.length ? (
                  feedData.feeds.map((feed, feedIndex) => (
                    <Fragment key={`feedpage_${feed._id}_${feedIndex}`}>
                      <ErrorBoundary>
                        <Suspense
                          fallback={<SkeletonLoading type={"post"} count={1} />}
                        >
                          <FeedViewer
                            feed={feed}
                            feedIndex={feedIndex}
                            isEditFeed={true}
                            editFeed={(editedFeed) =>
                              this._editFeed(editedFeed, feedIndex)
                            }
                            pinToProfile={(isPinned) =>
                              this._pinToProfile(feed._id, isPinned, feedIndex)
                            }
                            deletePost={() =>
                              this._deletePost(feed._id, feedIndex)
                            }
                            onComment={(index, isAdded) =>
                              this._onCommentPost(index, isAdded)
                            }
                            updateCommentsCount={({ index, value }) =>
                              this._updateCommentsCount({ index, value })
                            }
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </Fragment>
                  ))
                ) : feedLoading ? (
                  <SkeletonLoading type={"post"} count={4} />
                ) : (
                  <Card className="cardFeed">
                    <CardBody>
                      <div className="mediaPhotoWrap-Feed">
                        {WELCOME_POST_VIDEO ? (
                          <video
                            src={WELCOME_POST_VIDEO}
                            controls
                            controlsList="nodownload"
                            disablePictureInPicture
                          />
                        ) : (
                          <img
                            className="mediaPhoto-Feed"
                            src={WELCOME_POST_IMAGE}
                            alt="Welcome Img"
                            loading="lazy"
                          />
                        )}
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </InfiniteScroll>

        <SoundRecordUploadModal
          isOpen={this.state.modals[0]}
          onSave={(data) => this._saveSoundRecord(data)}
          onCancel={() => this._cancelSoundRecording()}
          uploadFile={(e) => this._onImageUpdate(e)}
          isUploadFile={false}
          toggle={() => this._toggleModal(0)}
          isMultipleUpload={false}
          isHideUploadAudioFile={true}
        />

        {/* Expiration Modal */}
        <ExpirationPeriodModal
          ref={this.expirationPeriodModalRef}
          isOpen={this.state.modals[1]}
          onSave={(isCustomdateSelected, data, expiryInDays) =>
            this._saveExpirationPeriod(isCustomdateSelected, data, expiryInDays)
          }
          onCancel={() => this._cancelExpirationPeriod()}
        />

        {/* Modal For Schedule post */}
        <SelectDateTimeModal
          ref={this.scheduleDateModalRef}
          isOpen={this.state.modals[4]}
          onSave={(data) => this._saveScheduledPostDate(data)}
          startDate={postedData["startDate"]}
          onCancel={() => this._cancelScheduledPost()}
        />

        {/* Modal for "Poll" */}
        <CreatePollModal
          isOpen={this.state.modals[2]}
          onModalToggle={(index) => this._toggleModal(index)}
          onSave={(data) => this._savePollForm(data)}
          onCancel={(index) => this._toggleModal(index)}
        />

        {/* Modal for "Post Price" */}
        <PostCostModal
          {...this.props}
          ref={this.postCostModalRef}
          isFreeContentForAll={isFreeContentForAll}
          price={postedData["postPrice"]}
          plusPrice={postedData["plusPostPrice"]}
          premiumPrice={postedData["premiumPostPrice"]}
          userData={userData}
          isOpen={this.state.modals[3]}
          onSave={({ basic, plus, premium, isFreeContentForAll }) =>
            this._savePostPrice({ basic, plus, premium, isFreeContentForAll })
          }
          onClose={() => this._toggleModal(3)}
          multiTierSubscription={userData?.user?.multiTierSubscription}
          isFreeContentPreviewOn={userData?.user?.isFreeContentPreviewOn}
        />

        {/* Modal for "Set Goal Amount" */}
        <SetGoalAmountModal
          isOpen={this.state.modals[5]}
          goalAmount={postedData["goalAmount"]}
          minimumPaymentForGoal={postedData["minimumPaymentForGoal"]}
          onSave={(goalAmount, minimumPaymentForGoal) =>
            this._saveGoalPrice(goalAmount, minimumPaymentForGoal)
          }
          onClose={() => this._toggleModal(5)}
        />

        <CreateNftModal
          isOpen={this.state.modals[6]}
          nftObject={postedData["nftObject"]}
          onSave={(nftObject) => this._saveNftObject(nftObject)}
          onClose={() => this._toggleModal(6)}
        />

        <AddFeedPreviewModal
          isOpen={addFeedPreviewModal.isOpen}
          previewVideoUrl={addFeedPreviewModal.previewVideoUrl}
          previewImageUrl={addFeedPreviewModal.previewImageUrl}
          toggle={() => this._toggleAddFeedPreviewModal()}
          onChangePreview={(event) => this._onChangePreview(event)}
          onCropPreview={(file) =>
            this.setState({ croppedPreviewContent: file }, () =>
              this._toggleCropModal(true)
            )
          }
          resetPreview={this._resetPreview}
          editMode={editMode || isPreviewAdded}
        />

        <ImageCropUploaderModal
          isOpen={isOpenCropModal}
          selectedPhoto={croppedPreviewContent}
          resetPhoto={() => {}}
          onSuccess={this._saveCroppedPhoto}
          onDismiss={() => this._toggleCropModal(false)}
        />

        {/* welcome creator modal */}
        <WelcomeCreatorModal />

        {/* <NewStoryModal
          isOpen={this.state?.newStoryModal?.isOpen}
          data={this.state?.newStoryModal?.data}
          category={"post"}
          toggle={() => this._toggleNewStoryModal()}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feedData: state.feedData,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosts: (pagination) => dispatch(fetchPosts(pagination)),
    fetchAndModifiedUserDetails: () => dispatch(fetchAndModifiedUserDetails()),
    deletePost: (index) => dispatch(deletePost(index)),
    updateAPost: ({ index, attr, value }) =>
      dispatch(updateAPost({ index, attr, value })),
    updateList: ({ index, post }) => dispatch(updateList({ index, post })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedPage);
